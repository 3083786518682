/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getClubmemberTermsList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/clubmember_terms/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} version
 */
export function getClubmemberTerms(apkihoncd, shopcd, version) {
  return apiService.get('customer/clubmember_terms', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      version: version,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} version
 * @params {string} pdffile
 */
export function putClubmemberTerms(apkihoncd, shopcd, version, update_data) {
  return apiService.put('customer/clubmember_terms', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    version: version,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 */
export function getClubmemberTermsSet(apkihoncd, shopcd) {
  return apiService.get('customer/clubmember_terms_set', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getImportantSubjectsList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/important_subjects/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} version
 */
export function getImportantSubjects(apkihoncd, shopcd, version) {
  return apiService.get('customer/important_subjects', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      version: version,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} version
 * @params {string} pdffile
 */

export function putImportantSubjects(apkihoncd, shopcd, version, update_data) {
  return apiService.put('customer/important_subjects', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    version: version,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 */
export function getImportantSubjectsSet(apkihoncd, shopcd) {
  return apiService.get('customer/important_subjects_set', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getPlanMasterList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/plan_master/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} plncd
 */
export function getPlanMaster(apkihoncd, shopcd, plncd) {
  return apiService.get('customer/plan_master', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      plncd: plncd,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} plncd
 * @params {string} pdffile
 */

export function putPlanMaster(apkihoncd, shopcd, plncd, update_data) {
  return apiService.put('customer/plan_master', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    plncd: plncd,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getCampaignMasterList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/campaign_master/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} campaigncd
 */
export function getCampaignMaster(apkihoncd, shopcd, campaigncd) {
  return apiService.get('customer/campaign_master', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      campaigncd: campaigncd,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} campaigncd
 * @params {string} pdffile
 */
export function putCampaignMaster(apkihoncd, shopcd, plncd, update_data) {
  return apiService.put('customer/campaign_master', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    campaigncd: campaigncd,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getPlanMasterSetList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/plan_master_set/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} index
 * @params {string} plncd
 */
export function putPlanMasterSet(apkihoncd, shopcd, index, update_data) {
  return apiService.put('customer/plan_master_set', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    index: index,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} index
 */

export function deletePlanMasterSet(apkihoncd, shopcd, index) {
  return apiService.delete('customer/plan_master_set', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    index: index
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getCampaignMasterSetList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/campaign_master_set/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} index
 * @params {string} campaigncd
 */
export function putCampaignMasterSet(apkihoncd, shopcd, index, update_data) {
  return apiService.put('customer/campaign_master_set', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    index: index,
    update_data: update_data
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} index
 */
export function deleteCampaignMasterSet(apkihoncd, shopcd, index) {
  return apiService.delete('customer/campaign_master_set', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    index: index
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} customer_id
 */
export function getAquaclaraInfo(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.get('customer/aquaclara_info', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} customer_id
 */
export function chkAquaclaraInfo(apkihoncd, shopcd, tokuisakicd, customer_id=null) {
  return apiService.get('customer/aquaclara_info/check', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id,
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} customer_id
 * @params {string} plncd
 * @params {string} campaigncd
 * @params {object} plan_update_data
 */
export function putAquaclaraInfo(apkihoncd, shopcd, tokuisakicd, customer_id, update_data, plan_update_data) {
  return apiService.put('customer/aquaclara_info', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    customer_id: customer_id,
    update_data: update_data,
    plan_update_data: plan_update_data
  });
}

