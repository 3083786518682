import store from 'store';
import _ from 'lodash';

const KEY_USER = 'user';
const KEY_SHOP = 'shop';
const KEY_KINDBOTTLE = 'kindbottle';
const KEY_CART = 'cart';
const KEY_BOXCART = 'boxcart';
const KEY_CHANGEPASS = 'changepass';
const KEY_INQUITY = 'inquity';
const KEY_TOKEN = 'token';
const KEY_AUTH_TOKEN = 'authtoken';
const KEY_MEMBERINFO = 'memberinfo';
const KEY_DELIV_TOKUISAKICD = 'delivtokuisakicd';
const KEY_ACCIS_ORDER = 'accisorder';
const KEY_BOTTLE_RARRY = 'bottlerally';
const KEY_TEMP_ACCIS = 'accistemporder';
const KEY_MYAQUA_REGIST_CAMPAIGN_REQ = 'myaquacampaignreq';
const KEY_MYAQUA_REGIST_CAMPAIGN_ID = 'myaquacampaignid';
const KEY_BIKO = 'biko';
const KEY_BIKO_URL = 'bikourl';
const KEY_JUMP_URL = 'jumpurl';
const KEY_PRODUCT_M_CATEGORY = 'productmcategory';
const KEY_PRODUCT_S_CATEGORY = 'productscategory';
const KEY_BILLING_SEND_STATUS_DISP = 'billingsendstatusdisp';
const KEY_WATER_BOTTLE_LIST = 'waterbottlelist';
const KEY_BEST_PRODUCTS_LIST = 'bestproductslist';
const KEY_INTRO_BANNER_IMAGE = 'introbannerimage';
const KEY_INTRO_BANNER_IMAGE_SP = 'introbannerimagesp';
const KEY_CAMPAIN_DETAIL_URL = 'campaindetailurl';
const KEY_PLAN_STATE = 'planstate';
const KEY_PLAN_SETCODE_12L = 'plansetcode12l';
const KEY_PLAN_SETCODE_7L = 'plansetcode7l';
const KEY_PLAN_SETCODE_12LNEW = 'plansetcode12lnew';
const KEY_PLAN_SETCODE_7LNEW = 'plansetcode7lnew';
const KEY_IS_REVISED_PRICE_CHANGE = 'isrevisedpricechange';
const KEY_KOSODATE_APP = 'kosodateapp';
const KEY_NINENWARI_APP = 'ninenwariapp';
const KEY_IS_ENTER_PLAN = 'isenterplan';
const KEY_CHILD_IMAGE = 'childimage';
const KEY_EXT_AUTH_DATE = 'extauthdate';
const KEY_TEMPORARY_REGIST = 'temporaryregist';
const KEY_PREREGIST_USERNAME = 'preregistusername';
const KEY_CLUBMENBER_TERMS_CHECK = 'clubmenbertermscheck';
const KEY_IMPORTANT_SUBJECTS_CHECK = 'importantsubjectscheck';
const KEY_NOTREACHED_MAIL_ADDRESS = 'keynotreachedmailaddress';
const KEY_NOTREACHED_MAIL_DATE = 'keynotreachedmaildate';
const KEY_AQUAALCRAMASTERINFO_TITLE = 'aquaclaramasterinfotitle';
const KEY_AQUAALCRAMASTERINFO_BODY = 'aquaclaramasterinfobody';
const KEY_AQUAALCRAMASTERINFO_IMAGE_URL = 'aquaclaramasterinfoimageurl';
const KEY_CURRENT_TOKUISAKICD = 'currenttokuisakicd';
const KEY_CURRENT_TOKUISAKIINFO = 'currenttokuisakiinfo';
const KEY_OYAKO_NEXTURL = 'oyakonexturl';
const KEY_OYAKO_PRODUCTID = 'oyakoproductid';
const KEY_OYAKO_QUANTITY = 'oyakoquantity';
const KEY_OYAKO_PRVORDER = 'oyakoprvorder';
const KEY_GOAL_ACHIEVEMENT = 'goalachievement';
const KEY_IS_SHOW_PRICE_CHANGE = 'isshowpricechange';

/**
 * Store the user information
 *
 * all of key will be changed to lower case even if add camel case or upper case.
 * if there is already has any information for user, it will be merged with stored object.
 *
 * @param {object}
 */
export function setUser(user) {
  return storeData(KEY_USER, user);
}

export function getUser() {
  return store.get(KEY_USER);
}

export function clearUser() {
  store.remove(KEY_USER);
}

export function setToken(token) {
  return store.set(KEY_TOKEN, token);
}

export function getToken() {
  return store.get(KEY_TOKEN);
}

export function clearToken() {
  store.remove(KEY_TOKEN);
}

export function setAuthToken(token) {
  return store.set(KEY_AUTH_TOKEN, token);
}

export function getAuthToken() {
  return store.get(KEY_AUTH_TOKEN);
}

export function clearAuthToken() {
  store.remove(KEY_AUTH_TOKEN);
}

export function setShop(shop) {
  return storeData(KEY_SHOP, shop);
}

export function getShop() {
  return store.get(KEY_SHOP);
}

export function clearShop() {
  store.remove(KEY_SHOP);
}

export function setKindBottle(kindbottle) {
  return storeData(KEY_KINDBOTTLE, kindbottle);
}

export function getKindBottle() {
  return store.get(KEY_KINDBOTTLE);
}

export function clearKindBottle() {
  store.remove(KEY_KINDBOTTLE);
}

export function setCart(cart) {
	return store.set(KEY_CART, cart);
}

export function getCart() {
  return store.get(KEY_CART);
}

export function clearCart() {
  store.remove(KEY_CART);
}

export function setBoxCart(cart) {
	return store.set(KEY_BOXCART, cart);
}

export function getBoxCart() {
  return store.get(KEY_BOXCART);
}

export function clearBoxCart() {
  store.remove(KEY_BOXCART);
}

export function setChangePass(changePass) {
  return storeData(KEY_CHANGEPASS, changePass);
}

export function getChangePass() {
  return store.get(KEY_CHANGEPASS);
}

export function clearChangePass() {
  store.remove(KEY_CHANGEPASS);
}

export function setInquiry(changeInquiry) {
  return storeData(KEY_INQUITY, changeInquiry);
}

export function getInquiry() {
  return store.get(KEY_INQUITY);
}

export function clearInquiry() {
  store.remove(KEY_INQUITY);
}

export function setAccisOrder(changeAccisOrder) {
  return storeData(KEY_ACCIS_ORDER, changeAccisOrder);
}

export function getAccisOrder() {
  return store.get(KEY_ACCIS_ORDER);
}

export function clearAccisOrder() {
  store.remove(KEY_ACCIS_ORDER);
}

export function setMemberInfo(changeMemberInfo) {
  return storeData(KEY_MEMBERINFO, changeMemberInfo);
}

export function getMemberInfo() {
  return store.get(KEY_MEMBERINFO);
}

export function clearMemberInfo() {
  store.remove(KEY_MEMBERINFO);
}

export function setDelivTokuisakiCd(delivTokuisakiCd) {
  return store.set(KEY_DELIV_TOKUISAKICD, delivTokuisakiCd);
}

export function getDelivTokuisakiCd() {
  return store.get(KEY_DELIV_TOKUISAKICD);
}

export function clearDelivTokuisakiCd() {
  store.remove(KEY_DELIV_TOKUISAKICD);
}

export function setBottleRally(bottleRallyInfo) {
  return storeData(KEY_BOTTLE_RARRY, bottleRallyInfo);
}

export function getBottleRally() {
  return store.get(KEY_BOTTLE_RARRY);
}

export function clearBottleRally() {
  store.remove(KEY_BOTTLE_RARRY);
}

export function setAccisTempOrder(changeAccisOrderTemp) {
  return store.set(KEY_TEMP_ACCIS, changeAccisOrderTemp);
}

export function getAccisTempOrder() {
  return store.get(KEY_TEMP_ACCIS);
}

export function setMyAquaCampaignReq(req) {
  return store.set(KEY_MYAQUA_REGIST_CAMPAIGN_REQ, req);
}

export function getMyAquaCampaignReq() {
  return store.get(KEY_MYAQUA_REGIST_CAMPAIGN_REQ);
}

export function setMyAquaCampaignId(campain_id) {
  return store.set(KEY_MYAQUA_REGIST_CAMPAIGN_ID, campain_id);
}

export function getMyAquaCampaignId() {
  return store.get(KEY_MYAQUA_REGIST_CAMPAIGN_ID);
}

export function setBiko(biko) {
  return store.set(KEY_BIKO, biko);
}

export function getBiko() {
  return store.get(KEY_BIKO);
}

export function setBikoUrl(url) {
  return store.set(KEY_BIKO_URL, url);
}

export function getBikoUrl() {
  return store.get(KEY_BIKO_URL);
}

export function clearBikoUrl() {
  store.remove(KEY_BIKO_URL);
}

export function setJumpUrl(url) {
  return store.set(KEY_JUMP_URL, url);
}

export function getJumpUrl() {
  return store.get(KEY_JUMP_URL);
}

export function clearJumpUrl() {
  store.remove(KEY_JUMP_URL);
}

export function setProductMCategory(category) {
	return store.set(KEY_PRODUCT_M_CATEGORY, category);
}

export function getProductMCategory() {
  return store.get(KEY_PRODUCT_M_CATEGORY);
}

export function clearProductMCategory() {
  store.remove(KEY_PRODUCT_M_CATEGORY);
}

export function setProductSCategory(category) {
  return store.set(KEY_PRODUCT_S_CATEGORY, category);
}

export function getProductSCategory() {
  return store.get(KEY_PRODUCT_S_CATEGORY);
}

export function clearProductSCategory() {
  store.remove(KEY_PRODUCT_S_CATEGORY);
}

export function setBillingSendStatusDisp(disp) {
  return store.set(KEY_BILLING_SEND_STATUS_DISP, disp);
}

export function getBillingSendStatusDisp() {
  return store.get(KEY_BILLING_SEND_STATUS_DISP);
}

export function clearBillingSendStatusDisp() {
  store.remove(KEY_BILLING_SEND_STATUS_DISP);
}

export function setWaterBottleList(list) {
  return store.set(KEY_WATER_BOTTLE_LIST, list);
}

export function getWaterBottleList() {
  return store.get(KEY_WATER_BOTTLE_LIST);
}

export function clearWaterBottleList() {
  store.remove(KEY_WATER_BOTTLE_LIST);
}

export function setBestProductsList(list) {
  return store.set(KEY_BEST_PRODUCTS_LIST, list);
}

export function getBestProductsList() {
  return store.get(KEY_BEST_PRODUCTS_LIST);
}

export function clearBestProductsList() {
  store.remove(KEY_BEST_PRODUCTS_LIST);
}

export function setIntroBannerImage(img) {
  return store.set(KEY_INTRO_BANNER_IMAGE, img);
}

export function getIntroBannerImage() {
  return store.get(KEY_INTRO_BANNER_IMAGE);
}

export function setIntroBannerImageSp(img) {
  return store.set(KEY_INTRO_BANNER_IMAGE_SP, img);
}

export function getIntroBannerImageSp() {
  return store.get(KEY_INTRO_BANNER_IMAGE_SP);
}

export function setCampainDetailUrl(url) {
  return store.set(KEY_CAMPAIN_DETAIL_URL, url);
}

export function getCampainDetailUrl() {
  return store.get(KEY_CAMPAIN_DETAIL_URL);
}

export function clearCampainDetailUrl() {
  store.remove(KEY_CAMPAIN_DETAIL_URL);
}

export function setPlanState(state) {
  return store.set(KEY_PLAN_STATE, state);
}

export function getPlanState() {
  return store.get(KEY_PLAN_STATE);
}

export function clearPlanState() {
  store.remove(KEY_PLAN_STATE);
}

export function setPlanSetCode12L(code) {
  return store.set(KEY_PLAN_SETCODE_12L, code);
}

export function getPlanSetCode12L() {
  return store.get(KEY_PLAN_SETCODE_12L);
}

export function clearPlanSetCode12L() {
  store.remove(KEY_PLAN_SETCODE_12L);
}

export function setPlanSetCode7L(code) {
  return store.set(KEY_PLAN_SETCODE_7L, code);
}

export function getPlanSetCode7L() {
  return store.get(KEY_PLAN_SETCODE_7L);
}

export function clearPlanSetCode7L() {
  store.remove(KEY_PLAN_SETCODE_7L);
}

export function setPlanSetCode12LNew(code) {
  return store.set(KEY_PLAN_SETCODE_12LNEW, code);
}

export function getPlanSetCode12LNew() {
  return store.get(KEY_PLAN_SETCODE_12LNEW);
}

export function clearPlanSetCode12LNew() {
  store.remove(KEY_PLAN_SETCODE_12LNEW);
}

export function setPlanSetCode7LNew(code) {
  return store.set(KEY_PLAN_SETCODE_7LNEW, code);
}

export function getPlanSetCode7LNew() {
  return store.get(KEY_PLAN_SETCODE_7LNEW);
}

export function clearPlanSetCode7LNew() {
  store.remove(KEY_PLAN_SETCODE_7LNEW);
}

export function setRevisedPriceChange(change) {
  return store.set(KEY_IS_REVISED_PRICE_CHANGE, change);
}

export function getRevisedPriceChange() {
  return store.get(KEY_IS_REVISED_PRICE_CHANGE);
}

export function clearRevisedPriceChange() {
  store.remove(KEY_IS_REVISED_PRICE_CHANGE);
}

export function setIsEnterPlan(enabled) {
  return store.set(KEY_IS_ENTER_PLAN, enabled);
}

export function getIsEnterPlan() {
  return store.get(KEY_IS_ENTER_PLAN);
}

export function clearIsEnterPlan() {
  store.remove(KEY_IS_ENTER_PLAN);
}

export function setKosodateApp(val) {
  return store.set(KEY_KOSODATE_APP, val);
}

export function getKosodateApp() {
  return store.get(KEY_KOSODATE_APP);
}

export function clearKosodateApp() {
  store.remove(KEY_KOSODATE_APP);
}

export function setNinenwariApp(val) {
  return store.set(KEY_NINENWARI_APP, val);
}

export function getNinenwariApp() {
  return store.get(KEY_NINENWARI_APP);
}

export function clearNinenwariApp() {
  store.remove(KEY_NINENWARI_APP);
}

export function setChildImage(image) {
  return store.set(KEY_CHILD_IMAGE, image);
}

export function getChildImage() {
  return store.get(KEY_CHILD_IMAGE);
}

export function setExtAuthDate(val) {
  return store.set(KEY_EXT_AUTH_DATE, val);
}

export function getExtAuthDate() {
  return store.get(KEY_EXT_AUTH_DATE);
}

export function clearExtAuthDate() {
  store.remove(KEY_EXT_AUTH_DATE);
}

export function setTemporaryRegist(val) {
  return store.set(KEY_TEMPORARY_REGIST, val);
}

export function getTemporaryRegist() {
  return store.get(KEY_TEMPORARY_REGIST);
}

export function clearTemporaryRegist() {
  store.remove(KEY_TEMPORARY_REGIST);
}

export function setPreRegistUserName(val) {
  return store.set(KEY_PREREGIST_USERNAME, val);
}

export function getPreRegistUserName() {
  return store.get(KEY_PREREGIST_USERNAME);
}

export function clearPreRegistUserName() {
  store.remove(KEY_PREREGIST_USERNAME);
}

export function setClubmenberTermsCheck(val) {
  return store.set(KEY_CLUBMENBER_TERMS_CHECK, val);
}

export function getClubmenberTermsCheck() {
  return store.get(KEY_CLUBMENBER_TERMS_CHECK);
}

export function clearClubmenberTermsCheck() {
  store.remove(KEY_CLUBMENBER_TERMS_CHECK);
}

export function setImportantSubjectsCheck(val) {
  return store.set(KEY_IMPORTANT_SUBJECTS_CHECK, val);
}

export function getImportantSubjectsCheck() {
  return store.get(KEY_IMPORTANT_SUBJECTS_CHECK);
}

export function clearImportantSubjectsCheck() {
  store.remove(KEY_IMPORTANT_SUBJECTS_CHECK);
}

export function setNotreachedMailAddress(val) {
  return store.set(KEY_NOTREACHED_MAIL_ADDRESS, val);
}

export function getNotreachedMailAddress() {
  return store.get(KEY_NOTREACHED_MAIL_ADDRESS);
}

export function clearNotreachedMailAddress() {
  store.remove(KEY_NOTREACHED_MAIL_ADDRESS);
}

export function setNotreachedMailDate(val) {
  return store.set(KEY_NOTREACHED_MAIL_DATE, val);
}

export function getNotreachedMailDate() {
  return store.get(KEY_NOTREACHED_MAIL_DATE);
}

export function clearNotreachedMailDate() {
  store.remove(KEY_NOTREACHED_MAIL_DATE);
}

export function setAquaclaramasterinfoTitle(val) {
  return store.set(KEY_AQUAALCRAMASTERINFO_TITLE, val);
}

export function getAquaclaramasterinfoTitle() {
  return store.get(KEY_AQUAALCRAMASTERINFO_TITLE);
}

export function clearAquaclaramasterinfoTitle() {
  store.remove(KEY_AQUAALCRAMASTERINFO_TITLE);
}

export function setAquaclaramasterinfoBody(val) {
  return store.set(KEY_AQUAALCRAMASTERINFO_BODY, val);
}

export function getAquaclaramasterinfoBody() {
  return store.get(KEY_AQUAALCRAMASTERINFO_BODY);
}

export function clearAquaclaramasterinfoBody() {
  store.remove(KEY_AQUAALCRAMASTERINFO_BODY);
}

export function setAquaclaramasterinfoImageUrl(val) {
  return store.set(KEY_AQUAALCRAMASTERINFO_IMAGE_URL, val);
}

export function getAquaclaramasterinfoImageUrl() {
  return store.get(KEY_AQUAALCRAMASTERINFO_IMAGE_URL);
}

export function clearAquaclaramasterinfoImageUrl() {
  store.remove(KEY_AQUAALCRAMASTERINFO_IMAGE_URL);
}

export function setCurrentTokuisakicd(val) {
  return store.set(KEY_CURRENT_TOKUISAKICD, val);
}

export function getCurrentTokuisakicd() {
  return store.get(KEY_CURRENT_TOKUISAKICD);
}

export function clearCurrentTokuisakicd() {
  store.remove(KEY_CURRENT_TOKUISAKICD);
}

export function setCurrentTokuisakiinfo(val) {
  return store.set(KEY_CURRENT_TOKUISAKIINFO, val);
}

export function getCurrentTokuisakiinfo() {
  return store.get(KEY_CURRENT_TOKUISAKIINFO);
}

export function clearCurrentTokuisakiinfo() {
  store.remove(KEY_CURRENT_TOKUISAKIINFO);
}

export function setOyakoNextUrl(val) {
  return store.set(KEY_OYAKO_NEXTURL, val);
}

export function getOyakoNextUrl() {
  return store.get(KEY_OYAKO_NEXTURL);
}

export function clearOyakoNextUrl() {
  store.remove(KEY_OYAKO_NEXTURL);
}

export function setOyakoProductId(val) {
  return store.set(KEY_OYAKO_PRODUCTID, val);
}

export function getOyakoProductId() {
  return store.get(KEY_OYAKO_PRODUCTID);
}

export function clearOyakoProductId() {
  store.remove(KEY_OYAKO_PRODUCTID);
}

export function setOyakoQuantity(val) {
  return store.set(KEY_OYAKO_QUANTITY, val);
}

export function getOyakoQuantity() {
  return store.get(KEY_OYAKO_QUANTITY);
}

export function clearOyakoQuantity() {
  store.remove(KEY_OYAKO_QUANTITY);
}

export function setOyakoPrvorder(val) {
  return store.set(KEY_OYAKO_PRVORDER, val);
}

export function getOyakoPrvorder() {
  return store.get(KEY_OYAKO_PRVORDER);
}

export function clearOyakoPrvorder() {
  store.remove(KEY_OYAKO_PRVORDER);
}

export function setGoalAchievement(val) {
  return store.set(KEY_GOAL_ACHIEVEMENT, val);
}

export function getGoalAchievement() {
  return store.get(KEY_GOAL_ACHIEVEMENT);
}

export function clearGoalAchievement() {
  store.remove(KEY_GOAL_ACHIEVEMENT);
}

export function setIsShowPriceChange(enabled) {
  return store.set(KEY_IS_SHOW_PRICE_CHANGE, enabled);
}

export function getIsShowPriceChange() {
  return store.get(KEY_IS_SHOW_PRICE_CHANGE);
}

export function clearIsShowPriceChange() {
  store.remove(KEY_IS_SHOW_PRICE_CHANGE);
}

function storeData(key, object) {
  let newData = _.mapKeys(object, (v, k) => {
    return _.toLower(k);
  });
  let storeData = _.merge(store.get(key), newData);

  store.set(key, storeData);
}

export function set(name, object) {
  store.set(name, object);
}

export function get(name) {
  return store.get(name);
}

export function del(name) {
  return store.remove(name);
}
