import * as cartService from '../service/cart-service';
import * as storageService from '../service/storage-service';
import * as cart from '../common/cart';
import {PAGES} from '../common/constant';

export function getOyako(next_url, productId, quantity) {
  var user = storageService.getUser();
  var promiseOyako = new Promise(function (resolve, reject) {
    cartService.getTokuisakicd(user.apkihoncd, user.shopcd, user.tokuisakicd, 0).then(function (response) {
      if (response.status == 200 || response.status == 201 || response.status == 204 || response.status == 405) {
        if (response.data.data.list_num == 1) {
          // 親子ではない
          resolve(0);
        } else {
          // 親子
          storageService.setOyakoNextUrl(next_url);
          storageService.setOyakoProductId(productId);
          storageService.setOyakoQuantity(quantity);
          resolve(1);
        }
      } else {
        reject(-1);
      }
    }).catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
  return promiseOyako;
}

export function processOyako(targeturlandquery, product_id, quantity) {
  var user = storageService.getUser();
  let oyakopromise = getOyako(targeturlandquery, product_id, quantity);
  Promise.all([oyakopromise]).then(function (result) {
    if (result[0] == 1) {
      if (storageService.getOyakoPrvorder() == 1) {
        window.location.href = PAGES.SELECT_OYAKO2;
      } else {
        window.location.href = PAGES.SELECT_OYAKO;
      }
    } else {
      storageService.setCurrentTokuisakicd(user.tokuisakicd);
      storageService.setCurrentTokuisakiinfo(user.user_info.S02M013T);
      if (product_id && quantity) {
        cart.addCart(product_id, quantity);
      } else {
        window.location.href = targeturlandquery;
      }
    }
  });
}

export function clearOyako() {
  storageService.clearCurrentTokuisakicd();
  storageService.clearCurrentTokuisakiinfo();
  storageService.clearOyakoPrvorder();
  storageService.clearOyakoNextUrl();
  storageService.clearOyakoProductId();
  storageService.clearOyakoQuantity();
}

