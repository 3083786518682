/*
 * Acquire customer information detail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserDetail(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.get('customer/user/info', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id
    }
  });
}

/*
 * Membership Management (Member Master: Edit)
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} need_csv_output
 * @params {string} account_name
 * @params {string} password
 * @params {number} status
 * @params {string} email
 * @params {string} email2
 * @params {number} sex
 * @params {number} job
 * @params {string} birth
 * @params {number} customer_type
 * @params {number} mailmaga_flg
 * @params {number} mailto_flg
 * @params {string} name1
 * @params {string} name2
 * @params {string} kana1
 * @params {string} kana2
 * @params {string} yubinno
 * @params {string} adrsn1
 * @params {string} adrsn2
 * @params {string} adrsn3
 * @params {string} tel
 * @params {string} fax
 * @params {number} reminder
 * @params {string} reminder_answer
 * @params {string} note
 * @params {string} btl_rally_flg
 */

export function putUserInfo(apkihoncd, shopcd, tokuisakicd, need_csv_output, update_data, is_recovery_flg=null) {
  return apiService.put('customer/user/info', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    need_csv_output: need_csv_output,
    update_data: update_data,
    is_recovery_flg: is_recovery_flg
  });
}

/*
 * Membership Management (Member Master: Official Edit)
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 */
export function putUserOfficial(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.put('customer/users/official', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    customer_id: customer_id,
  });
}

/*
 * New user registration
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} tel
 * @params {string} email
 * @params {string} email2
 * @params {number} sex
 * @params {number} job
 * @params {string} birth
 * @params {number} customer_type
 * @params {string} account_name
 * @params {number} reminder
 * @params {string} reminder_answer
 * @params {number} mailmaga_flg
 * @params {number} mailto_flg
 * @params {number} bill_confirm_mail_flg
 * @params {number} temporary_flg
 * @params {string} temporary_password
 */
export function postMemberSignUp(apkihoncd, shopcd, tokuisakicd, tel, email, email2, sex, job, birth, customer_type, account_name, reminder, reminder_answer, mailmaga_flg, mailto_flg, bill_confirm_mail_flg, temporary_flg=null, temporary_password=null) {
  return apiService.post('customer/users', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    tel: tel,
    email: email,
    email2: email2,
    sex: sex,
    job: job,
    birth: birth,
    customer_type: customer_type,
    account_name: account_name,
    reminder: reminder,
    reminder_answer: reminder_answer,
    mailmaga_flg: mailmaga_flg,
    mailto_flg: mailto_flg,
    bill_confirm_mail_flg: bill_confirm_mail_flg,
    temporary_flg: temporary_flg,
    temporary_password: temporary_password
  });
}

/*
 * Acquire new user registration availability
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} tel
 */
export function getUserAvailability(apkihoncd, shopcd, tokuisakicd, tel) {
  return apiService.get('customer/availability', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      tel: tel
    }
  });
}

/*
 * Acquire new user registration availability
 * @params {string} email
 * @params {string} account_name
 * @params {number} mode
 */
export function getUserAvailabilityMailAddress(email, account_name, mode) {
  let p = apiService.get('customer/availability/mailaddress', {
    params: {
      email: email,
      account_name: account_name,
      mode: mode
    }
  });
  return p;
}

/*
 * Acquire new user registration availability(verify)
 * @params {string} email
 * @params {number} verify_code
 */
export function getUserAvailabilityMailAddressVerify(email, verify_code) {
  let p = apiService.get('customer/availability/mailaddress/verify', {
    params: {
      email: email,
      verify_code: verify_code
    }
  });
  return p;
}

/*
 * Acquire new user basic information
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserBasic(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/basic', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * My aqua login check
 * @params {string} username
 */
export function getUserLoginMyaqua(username, temporary_password=null) {
  return apiService.get('customer/login/myaqua', {
    params: {
      username: username,
      temporary_password: temporary_password
    }
  });
}

/*
 * My aqua password get
 * @params {string} username
 */
export function getUserLoginPassword(username) {
  return apiService.get('customer/login/password', {
    params: {
      username: username,
    }
  });
}

/*
 * Push notification settings
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} gcm_id
 */
export function putUserPushRegist(apkihoncd, shopcd, tokuisakicd, gcm_id) {
  return apiService.put('customer/push/regist', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    gcm_id: gcm_id
  });
}

/*
 * Cognito ID setting
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} _id
 */
export function putUserCognitoIdentityId(apkihoncd, shopcd, tokuisakicd, identity_id) {
  return apiService.put('customer/user/identityid', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    identity_id: identity_id
  });
}

/*
 * Cognito identity logins
 */
export function getUserCognitoIdentityLogins(identity_id) {
  return apiService.get('customer/user/logins', {params:{
    identity_id: identity_id
  }});
}

/*
 * Acquire Server Maintenance Notice
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserServerMaintenace(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/server_maintenace', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Acquired Baby Aqua update month notice
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserBabyaqua(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/babyaqua', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Acquired Ninenwari update month notice
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserNinenwari(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/ninenwari', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * User login verification of old system
 * @params {string} username
 * @params {string} password
 */
export function getUserLoginOld(username, password) {
  return apiService.get('customer/login_old', {
    params: {
      username: username,
      password: password
    }
  });
}

/*
 * Confirm password reset
 * @params {string} email
 */
export function getUserPassConfirm(email) {
  return apiService.get('customer/user/password_confirm', {
    params: {
      email: email
    }
  });
}

/*
 * Resetting a password
 * @params {string} account_name
 * @params {string} reminder_answer
 */
export function getUserPassReset(account_name, reminder_answer) {
  return apiService.get('customer/user/password_reset', {
    params: {
      account_name: account_name,
      reminder_answer: reminder_answer
    }
  });
}

/*
 * Resetting a password with password
 * @params {string} account_name
 * @params {string} email
 * @params {number} reminder
 * @params {string} reminder_answer
 * @params {string} password
 */
export function putUserPassReset(account_name, email, reminder, reminder_answer, password) {
  return apiService.put('customer/user/password_reset', {
    params: {
      account_name: account_name,
      email: email,
      reminder: reminder,
      reminder_answer: reminder_answer,
      password: password
    }
  });
}

/*
 * Get customer biko
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 */
export function getUserBiko(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.get('customer/customer_biko', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id,
    }
  });
}

/*
 * Put customer biko
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 * @params {string} biko
 */
export function putUserBiko(apkihoncd, shopcd, tokuisakicd, customer_id, biko, prev_biko) {
  return apiService.put('customer/customer_biko', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    customer_id: customer_id,
    biko: biko,
    prev_biko: prev_biko,
  });
}

/*
 * Get customer not reached mail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 */
export function getUserNotreachedMail(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.get('customer/customer_notreached_mail', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id,
    }
  });
}

/*
 * Put customer not reached mail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 * @params {string} email
 */
export function putUserNotreachedMail(apkihoncd, shopcd, tokuisakicd, customer_id, email) {
  return apiService.put('customer/customer_notreached_mail', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    customer_id: customer_id,
    email: email
  });
}

/*
 * Delete customer not reached mail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {number} customer_id
 */
export function deleteUserNotreachedMail(apkihoncd, shopcd, tokuisakicd, customer_id) {
  return apiService.delete('customer/customer_notreached_mail', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id
    }
  });
}

/*
 * Regular orders Delivery date acquired
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserDlveryFixedDayList(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/dlvery/fixed_day_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Regular orders Delivery date acquired
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getUserDlverySpecifyFixedDayList(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/dlvery/specify_fixed_day_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Acquire Delivery Available Date List
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} dlvryareacd
 * @params {string} dlvrykankatsukyotencd
 * @params {string} dlvrykankatsushopcd
 * @params {string} dlvrykankatsuapkihoncd
 * @params {string} page_no
 * @params {string} page_max_count
 */
export function getUserDlveryDayList(apkihoncd, shopcd, dlvryareacd, dlvrykankatsukyotencd, dlvrykankatsushopcd, dlvrykankatsuapkihoncd, page_no, page_max_count) {
  return apiService.get('customer/dlvery/day_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      dlvryareacd: dlvryareacd,
      dlvrykankatsukyotencd: dlvrykankatsukyotencd,
      dlvrykankatsushopcd: dlvrykankatsushopcd,
      dlvrykankatsuapkihoncd: dlvrykankatsuapkihoncd,
      page_no: page_no,
      page_max_count: page_max_count
    }
  });
}				

/*
 * Acquire delivery over limit date list
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} dlvryareacd
 * @params {string} dlvrykankatsukyotencd
 * @params {string} page_no
 * @params {string} page_max_count
 */
export function getUserDlveryLimitOverList(apkihoncd, shopcd, dlvryareacd, dlvrykankatsukyotencd, page_no, page_max_count) {
  return apiService.get('customer/dlvery/limit_over_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      dlvryareacd: dlvryareacd,
      dlvrykankatsukyotencd: dlvrykankatsukyotencd,
      page_no: page_no,
      page_max_count: page_max_count
    }
  });
}

/*
 * 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} dlvryareacd
 * @params {string} name
 * @params {string} seal_num
 */
export function putUserBottleSea(apkihoncd, shopcd, tokuisakicd, name, seal_num) {
  return apiService.put('customer/bottle_seal', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    name: name,
    seal_num: seal_num
  });
}

/*
 * Resetting a password with password
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} email
 * @params {number} mailmaga_flg

 */
export function putMailmagaHistory(apkihoncd, shopcd, tokuisakicd, email, mailmaga_flg) {
  return apiService.put('customer/mail_mailmaga/history', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    email: email,
    mailmaga_flg: mailmaga_flg
  });
}

/*
 * Resetting a password with password
 * @params {string} account_name
 * @params {string} email
 * @params {number} reminder
 * @params {string} reminder_answer
 * @params {string} new_account_name
 */
export function putUserNewMyaquaid(account_name, email, reminder, reminder_answer, new_account_name) {
  return apiService.put('customer/user/myaquaid', {
      account_name: account_name,
      email: email,
      reminder: reminder,
      reminder_answer: reminder_answer,
      new_account_name: new_account_name
  });
}

/*
 * Regular orders Delivery date acquired
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} yearmonth
 */
export function getBusinessDayList(apkihoncd, shopcd, dlvrykankatsuapkihoncd, dlvrykankatsushopcd, dlvryareacd, dlvrykankatsukyotencd, yearmonth) {
  return apiService.get('customer/server_setup_daylist', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      dlvrykankatsuapkihoncd: dlvrykankatsuapkihoncd,
      dlvrykankatsushopcd: dlvrykankatsushopcd,
      dlvryareacd: dlvryareacd,
      dlvrykankatsukyotencd: dlvrykankatsukyotencd,
      yearmonth: yearmonth
    }
  });
}

/*
 * Get Aquaclara Master Info
 * @params {string} apkihoncd
 * @params {string} shopcd
 */
export function getAauaclaraMasterInfo(apkihoncd, shopcd) {
  return apiService.get('customer/aquaclarainfo_master', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd
    }
  });
}

/*
 * Get Goal Achievement Master Info
 * @params {string} apkihoncd
 * @params {string} shopcd
 */
export function getGoalAchievementMasterInfo(apkihoncd, shopcd) {
  return apiService.get('customer/goal_achievement/master', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd
    }
  });
}

/*
 * Get Goal Achievement Info
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {int} customer_id
 * @params {int} bottle_num
 */
export function getGoalAchievementInfo(apkihoncd, shopcd, tokuisakicd, customer_id, bottle_num) {
  return apiService.get('customer/goal_achievement', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      customer_id: customer_id,
      bottle_num: bottle_num,
    }
  });
}

