import * as cartService from '../service/cart-service';
import * as productService from '../service/product-service';
import * as revisedPriceService from '../service/revisedprice-service';
import * as storageService from '../service/storage-service';
import {PAGES} from '../common/constant';
import {PRODUCT_ID_12L} from '../common/constant';
import {PRODUCT_ID_7L} from '../common/constant';
import {PRODUCT_ID_12L_PLAN} from '../common/constant';
import {PRODUCT_ID_7L_PLAN} from '../common/constant';
import {PRODUCT_ID_12L_PLAN_NEW} from '../common/constant';
import {PRODUCT_ID_7L_PLAN_NEW} from '../common/constant';

var user = storageService.getUser();

export function addCart(productId, quantity) {
  var current_tokuisakicd = user.tokuisakicd;
  var productid = productId;
  // 親子選択がされている場合はその得意先コードと得意先マスタ情報を使用する。
  if (storageService.getCurrentTokuisakicd()) {
    current_tokuisakicd = storageService.getCurrentTokuisakicd();
  }
  let promiseProductIdChange = new Promise(function (resolve, reject) {
    revisedPriceService.getPlanState(user.apkihoncd, user.shopcd, current_tokuisakicd).then(function (response) {
      if (response.status == 200 || response.status == 201 || response.status == 204) {
        revisedPriceFunc.getRevisedPriceChange().then(function (result) {
          if (result == 0) {
            // 商品プロダクトIDがプランと異なる場合は変換する。
            if (response.data.data.result == 3 || response.data.data.result == 4) { // 2年割プラン or 子育てアクアプラン の 特別単価ではないケース
              // プラン商品
              if (productId == PRODUCT_ID_12L) {
                productid = PRODUCT_ID_12L_PLAN;
              } else if (productId == PRODUCT_ID_7L) {
                productid = PRODUCT_ID_7L_PLAN;
              }
            } else { // 通常プラン or (2年割プラン or 子育てアクアプラン の 特別単価) のケース
              // 通常商品
              if (productId == PRODUCT_ID_12L_PLAN) {
                productid = PRODUCT_ID_12L;
              } else if (productId == PRODUCT_ID_7L_PLAN) {
                productid = PRODUCT_ID_7L;
              }
            }
          } else {
            // 商品プロダクトIDがプランと異なる場合は変換する。
            if (response.data.data.result == 3 || response.data.data.result == 4) { // 2年割プラン or 子育てアクアプラン の 特別単価ではないケース
              // プラン商品
              if (productId == PRODUCT_ID_12L) {
                productid = PRODUCT_ID_12L_PLAN_NEW;
              } else if (productId == PRODUCT_ID_7L) {
                productid = PRODUCT_ID_7L_PLAN_NEW;
              }
            } else { // 通常プラン or (2年割プラン or 子育てアクアプラン の 特別単価) のケース
              // 通常商品
              if (productId == PRODUCT_ID_12L_PLAN_NEW) {
                productid = PRODUCT_ID_12L;
              } else if (productId == PRODUCT_ID_7L_PLAN_NEW) {
                productid = PRODUCT_ID_7L;
              }
            }
          }
          resolve(1);
        });
      } else {
        resolve(1);
      }
    });
  });
  promiseProductIdChange.then(function (result) {
    productService.getProductDetail(user.apkihoncd, user.shopcd, current_tokuisakicd, productid, '0').then(function (response) {
      const productDetail = response.data.data;
      //console.log(productDetail);
      var cartStorage = storageService.getCart();
      if (typeof cartStorage == 'undefined') {
        cartStorage = {
          product_list: {}
        };
      }
      cartStorage.total_count = 0;
      cartStorage.total_price = 0;
      cartStorage.total_price2 = 0;
      if (typeof cartStorage.product_list[productid] != 'undefined') {
        productDetail.count = parseInt(cartStorage.product_list[productid].count) + parseInt(quantity);
      } else {
        productDetail.count = parseInt(quantity);
      }
      cartStorage.product_list[productid] = productDetail;
      for (var product_id in cartStorage.product_list) {
        var projectObject = cartStorage.product_list[product_id];
        cartStorage.total_count += projectObject.count;
        cartStorage.total_price += projectObject.count * projectObject.notax_price;
        if (projectObject.revised_notax_price) {
          cartStorage.total_price2 += projectObject.count * projectObject.revised_notax_price;
        }
      }
      storageService.setCart(cartStorage);
      window.location.href = PAGES.CART;
    }).catch(function (error) {
      //console.log(error);
    });
  });
}
