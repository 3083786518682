import { PAGES } from '../common/constant';
import * as storageService from '../service/storage-service';
import * as userService from '../service/user-service';
import * as authenticationService from '../service/authentication-service';
import * as introService from '../service/intro-service';
import '../lib/oauth.min';
var next_list = [];
next_list.push('mypage-accept-confirm-change-mail.html')
next_list.push('mypage-accept-confirm-change-mail2.html')
next_list.push('campaign_list.html')
next_list.push('campaign_detail.html')
next_list.push(PAGES.INTRO_CAMPAIN)
next_list.push(PAGES.INTRO_CAMPAIN2)
next_list.push(PAGES.INTRO_CAMPAIN3)
/* TODO 3個にする場合 intro_campain3.ejsを追加して PAGES.INTRO_CAMPAIN3 をここに追加 */
next_list.push('intro-campain.html')
next_list.push('mypage-change-address.html')
next_list.push('inquiry.html')
next_list.push('mypage-top.html')
next_list.push('mypage-service-information.html')
next_list.push(PAGES.BIKO)
next_list.push(PAGES.CONFIRMATION_NINENWARI2)
next_list.push(PAGES.PLAN)
next_list.push(PAGES.COMPETED_BOXORDER_MAIL)
next_list.push(PAGES.BOX_INFORMATION)
next_list.push(PAGES.BOXIMAGE_DISPLAY)
next_list.push(PAGES.BOXORDER_DELIVERY_NOTE)
next_list.push(PAGES.EPOSCARD_REGIST)
next_list.push(PAGES.SODASTREAM)
next_list.push(PAGES.GOSHUIN)
next_list.push(PAGES.KIRISHIMA)
next_list.push(PAGES.CHAPLIN_TOP)
next_list.push(PAGES.CHAPLIN_CURRENT)
next_list.push(PAGES.CHANGE_EMAIL2)

var doc_ref = document.referrer;
var current_host = location.host;
var next_page = PAGES.TOP;
var new_regist_campain_id = 36;  // 指定URLのアクセスでなくても紹介キャンペーンの対象に無条件にする対応 => 紹介キャンペーンIDが変わった場合は注意!
var new_regist_campain_req = 2;  // 指定URLのアクセスでなくても紹介キャンペーンの対象に無条件にする対応
if (doc_ref && doc_ref.length > 0) {
  //console.log("login docref=" + doc_ref);
  if (doc_ref.indexOf(current_host) !== -1) { // ホスト名の一致確認
    for (var i = 0; i < next_list.length; i++) {
      if (doc_ref.indexOf(next_list[i]) !== -1) {
        next_page = doc_ref;
        if (next_list[i] == 'campaign_detail.html') {
          checkNewRegistCampaign(next_page);
        } else if (next_list[i] == PAGES.INTRO_CAMPAIN || next_list[i] == PAGES.INTRO_CAMPAIN2) {
                   /* TODO 3個にする場合 intro_campain3.ejsを追加して PAGES.INTRO_CAMPAIN3 を orで判定 */
          checkNewRegistCampaign2(next_page);
        } else if (next_list[i] == PAGES.BIKO
                    || next_list[i] == PAGES.COMPETED_BOXORDER_MAIL || next_list[i] == PAGES.BOX_INFORMATION
                    || next_list[i] == PAGES.BOXIMAGE_DISPLAY || next_list[i] == PAGES.BOXORDER_DELIVERY_NOTE
                    || next_list[i] == PAGES.EPOSCARD_REGIST || next_list[i] == PAGES.SODASTREAM || next_list[i] == PAGES.INTRO_CAMPAIN3
                    || next_list[i] == PAGES.GOSHUIN || next_list[i] == PAGES.KIRISHIMA || next_list[i] == PAGES.CHAPLIN_TOP || next_list[i] == PAGES.CHAPLIN_CURRENT || next_list[i] == PAGES.CHANGE_EMAIL2
                    || next_list[i] == 'mypage-accept-confirm-change-mail.html' || next_list[i] == 'mypage-accept-confirm-change-mail2.html') {
          storageService.setJumpUrl(next_page);
        }
        break;
      }
    }
  }
}

function checkNewRegistCampaign(url) {
  let ididx = url.indexOf("campain_id");
  let kindidx = url.indexOf("campain_kind");
  let startidx = ididx + 11;
  let endidx = kindidx - 1;
  let campain_id = url.substring(startidx, endidx);
  //console.log("campain_id=" + campain_id);
  startidx = kindidx + 13;
  endidx = startidx + 1;
  let campain_kind = next_page.substring(startidx, endidx);
  //console.log("campain_kind=" + campain_kind);
  campaignService.getMyAquaRegistCampaign(campain_id, campain_kind, 0).then(function (result) {
    if (result[0] == 1 || result[0] == 2) {
      //console.log("new regist campaign!");
      storageService.setMyAquaCampaignReq(1);
      new_regist_campain_req = 1;
      storageService.setMyAquaCampaignId(campain_id);
      new_regist_campain_id = campain_id;
      let mql = window.matchMedia('screen and (max-width: 769px)');
      if (mql.matches && result[0] == 1) {
        //console.log("smartphone => scroll");
        var element = document.getElementById('new'); // 移動させたい位置の要素を取得
        var rect = element.getBoundingClientRect();
        var position = rect.top;    // 一番上からの位置を取得
        //console.log("scroll postion=" + position);
        window.scrollTo(0, position);
      }
    } else {
      storageService.setCampainDetailUrl(url);
      //console.log("not new regist campaign");
    }
  });
}

function checkNewRegistCampaign2(url) {

  var today = new Date();
  today = today.getFullYear() + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2);
  today = new Date(today).getTime();
  var targeturl = url;
  campaignService.getCampaignList("001", "000", 2, 1, 100).then(function (response) {
    if (response.status == 200 || response.status == 201 || response.status == 204) {
      let campaignList = response.data.data.campain_list;
      if (campaignList && campaignList.length > 0) {
        let startDate = "";
        let endDate = "";
        let campaignObject = {};
        let isExec = false;
        for(let i = 0; i < campaignList.length; i++) {
          if (isExec) {
            break;
          }
          campaignObject = campaignList[i];
          startDate = campaignObject.start_date;
          endDate = campaignObject.end_date;
          if (startDate != null && endDate != null) {
            startDate = startDate.slice(0, 4) + '/' + startDate.slice(5, 7) + '/' + startDate.slice(8, 10);
            endDate = endDate.slice(0, 4) + '/' + endDate.slice(5, 7) + '/' + endDate.slice(8, 10);
            startDate = new Date(startDate).getTime();
            endDate = new Date(endDate).getTime();
          }
          if (startDate <= today && today <= endDate && campaignObject.campain_kind == 5) {
            campaignService.getMyAquaRegistCampaign(parseInt(campaignObject.campain_id), parseInt(campaignObject.campain_kind), 0).then(function (result) {
              if ((result[0] == 1 || result[0] == 2) && targeturl == result[3]) {
                //console.log("new regist campaign!");
                storageService.setMyAquaCampaignReq(2);
                new_regist_campain_req = 2;
                storageService.setMyAquaCampaignId(result[2]);
                new_regist_campain_id = result[2];
                let mql = window.matchMedia('screen and (max-width: 769px)');
                if (mql.matches && result[0] == 1) {
                  //console.log("smartphone => scroll");
                  var element = document.getElementById('new'); // 移動させたい位置の要素を取得
                  var rect = element.getBoundingClientRect();
                  var position = rect.top;    // 一番上からの位置を取得
                  //console.log("scroll postion=" + position);
                  window.scrollTo(0, position);
                }
                isExec = true;
              } else {
                //console.log("not new regist campaign");
              }
            });
          }
        }
      }
    }
  });
}

$(function () {
  // for register form
  $('#form-firsttime').submit(function (e) {
    e.preventDefault();
  }).validate({
    onkeyup: function (e) {
      $(e).valid();
    },
    rules: {
      'member-code': {
        required: true, number: true, rangelength: [12, 12]
      },
      tel: {
        required: true, number: true, rangelength: [10, 13]
      },
      'agree-use-information': {
        required: true
      }
    },
    submitHandler: function (form) {
      let [memberCd, tel] = [$('#member-code').val(), $('#tel').val()];
      let [apkihonCd, shopCd, tokuisakiCd] = [memberCd.slice(0, 3), memberCd.slice(3, 6), memberCd.slice(6, 12)];
      userService.getUserAvailability(apkihonCd, shopCd, tokuisakiCd, tel).then(function (response) {
        if (response.status == 200 || response.status == 201 || response.status == 204) {
          storageService.setUser({ 'apkihoncd': apkihonCd, 'shopcd': shopCd, 'tokuiskicd': tokuisakiCd, 'tel': tel });
          //console.log("new regist1:storageService.getMyAquaCampaignReq()=" + storageService.getMyAquaCampaignReq());
          //console.log("new regist2:new_regist_campain_id=" + new_regist_campain_id);

          if (storageService.getMyAquaCampaignReq() == 1 || storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_id > 0) {
            if (new_regist_campain_id == 0) {
              new_regist_campain_id = storageService.getMyAquaCampaignId();
              //console.log("new regist3:new_regist_campain_id=" + new_regist_campain_id);
            }
            if (storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_req == 2) {
              introService.getIntroOrigin(apkihonCd, shopCd, tokuisakiCd).then(function (response) {
                if (response.status == 200 || response.status == 201 || response.status == 204) {
                  let intro = response.data.data;
                  if (intro.result == '1') {
                    campaignService.putNewRegist(apkihonCd, shopCd, tokuisakiCd, new_regist_campain_id, 1).then(function (response) {
                      //console.log("new regist4");
                      if (response.status == 200 || response.status == 201 || response.status == 204) {
                        //console.log("new regist5");
                        storageService.setMyAquaCampaignReq(0);
                        storageService.setMyAquaCampaignId(new_regist_campain_id); // 指定URLのアクセスでなくても紹介キャンペーンの対象に無条件にする対応 => 紹介キャンペーンIDが変わった場合は注意!
                      }
                      //もし新規登録キャンペーン対象者登録に失敗しても画面に遷移する
                      location.href = PAGES.TERMS_OF_SERVICE;
                    }).catch(function (error) {
                      //console.log("new regist6");
                      //もし新規登録キャンペーン対象者登録に失敗しても画面に遷移する
                      location.href = PAGES.TERMS_OF_SERVICE;
                    });
                  } else {
                    location.href = PAGES.TERMS_OF_SERVICE;
                  }
                } else {
                  location.href = PAGES.TERMS_OF_SERVICE;
                }
              }).catch(function (error) {
                location.href = PAGES.TERMS_OF_SERVICE;
              });
            } else {
              campaignService.putNewRegist(apkihonCd, shopCd, tokuisakiCd, new_regist_campain_id, 1).then(function (response) {
                //console.log("new regist4");
                if (response.status == 200 || response.status == 201 || response.status == 204) {
                  //console.log("new regist5");
                  storageService.setMyAquaCampaignReq(0);
                }
                //もし新規登録キャンペーン対象者登録に失敗しても画面に遷移する
                location.href = PAGES.TERMS_OF_SERVICE;
              }).catch(function (error) {
                //console.log("new regist6");
                //もし新規登録キャンペーン対象者登録に失敗しても画面に遷移する
                location.href = PAGES.TERMS_OF_SERVICE;
              });
            }
          } else {
            //console.log("new regist7");
            location.href = PAGES.TERMS_OF_SERVICE;
          }
        } else {
          if (response.status == 405) {
            var btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
            $('.js-message-checkcode').html(btnCloseErrMessage + 'ただいまアクセスが集中しております。<br>しばらくたってから再度お試しください。').addClass('is-active');
          } else if (response.status == 404) {
            var btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
            $('.js-message-checkcode').html(btnCloseErrMessage + 'ただいま準備中です。詳しくは担当販売店にお問合せください。').addClass('is-active');
          } else {
            var btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
            $('.js-message-checkcode').html(btnCloseErrMessage + 'すでに会員登録済みか入力内容に誤りがあります。').addClass('is-active');
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  });
  // for login form
  $('#form-login').submit(function (e) {
    e.preventDefault();
  }).validate({
    onkeyup: function (e) {
      $(e).valid();
    },
    rules: {
      'nickname': { required: true, rangelength: [4, 128] },
      'password': { required: true, rangelength: [4, 10] },
    },
    submitHandler: function (form) {
      let [username, password] = [$("#nickname").val(), $("#password").val()];
      //console.log("login:next_page=" + next_page + " new_regist_campain_id=" + new_regist_campain_id);
      authenticationService.login(username, password, next_page, new_regist_campain_id, new_regist_campain_req);
      return false;
    }
  });
  //Check logging
  if (typeof storageService.getUser() != "undefined") {
    authenticationService.checkLogging();
  } else {
    $('body').removeClass('box-hidden');
  }

  //Remember a Device  
  if (storageService.get("rememberDevice") != null) {
    if (storageService.get("rememberDevice").flag == 1) {
      $('.js-remember-account').prev().prop('checked', true);
    }
  }
  $(document).on('click', '.js-remember-account', function () {
    let checkBox = $(this).prev();
    if (checkBox.prop('checked') == false) {
      //checked true
      storageService.set('rememberDevice', { flag: 1 });
    } else {
      storageService.set('rememberDevice', { flag: 0 });
    }
  });
});

window.fbAsyncInit = function() {
  //console.log("FB.init");
  FB.init({
    appId   : env.Facebook.appId,
    cookie  : true,
    xfbml   : true,
    version : 'v2.8'
  });
};
// Load the SDK asynchronously
(function(d, s, id) {
 var js, fjs = d.getElementsByTagName(s)[0];
 if (d.getElementById(id)) return;
 js = d.createElement(s); js.id = id;
 js.src = "https://connect.facebook.net/en_US/sdk.js";
 fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

var LineLogin = require('../lib/line_login.js');

$(document).ready(function () {

  var remember_me = $('#agree').prop("checked");
  if (remember_me === true) {
    var auto_account = JSON.parse(localStorage.getItem('auto_login'))
    $("#nickname").val(auto_account.userID)
    $("#password").val(auto_account. pswd)
  }
  if (window.location.search != "") {
    authenticationService.myaquaAuth(1);
  }

  // facebook login
  $(document).on("click", '#form-facebook-login', function () {
    storageService.clearToken();
    storageService.clearAuthToken();
    storageService.clearExtAuthDate();
    //console.log("FB.logout check");
    fblogin();
  });

  // twitter login
  $(document).on("click", '#form-twitter-login', function () {
    storageService.clearToken();
    storageService.clearAuthToken();
    storageService.clearExtAuthDate();
    OAuth.initialize(env.OAuthIo.PublicKey);
    OAuth.popup("twitter", function(error, response) {
      if (error) {
        showErrorMessage('Xのログインに失敗しました');
      } else {
        var accessToken = response.oauth_token+";"+response.oauth_token_secret;
        authenticationService.externalIdAuth('api.twitter.com', accessToken, new_regist_campain_id, new_regist_campain_req, function(err, res) {
          if (err) {
            showErrorMessage(err.message);
          } else {
            window.location.href = next_page;
          }
        });
      }
    });
  });

  // LINE login
  $(document).on("click", '#form-line-login', function () {
    storageService.clearToken();
    storageService.clearAuthToken();
    storageService.clearExtAuthDate();
    var authorize_url = 'https://'+env.AppWebDomainForLINE+'/oauth2/authorize';
    var redirect_url = window.location.protocol+'//'+window.location.host+'/api/v1/line/idpresponse';
    //authenticationService.getIdpresponse3(authorize_url, env.ClientIdForLINE, redirect_url).then(function (response) {
    //  if (response.status == 200 || response.status == 201 || response.status == 204) {
        //var location = response.data.data.location;
        var lineLogin = new LineLogin();
        lineLogin.popup(authorize_url, env.ClientIdForLINE, redirect_url, function(error, response) {
          if (response && response.id_token) {
            authenticationService.externalIdAuth("cognito-idp."+env.Region+".amazonaws.com/"+env.UserPoolIdForLINE, response.id_token, new_regist_campain_id, new_regist_campain_req, function(err, res) {
              if (err) {
                showErrorMessage(err.message);
              } else {
                window.location.href = next_page;
              }
            });
          } else {
            if (error && error.message == "timeout") {
              // Edge の timeout のケース
              //console.log("LINE login timeout error -> retry");
              lineLogin.popup(authorize_url, env.ClientIdForLINE, redirect_url, function(error, response) {
                if (response && response.id_token) {
                  authenticationService.externalIdAuth("cognito-idp."+env.Region+".amazonaws.com/"+env.UserPoolIdForLINE, response.id_token, new_regist_campain_id, new_regist_campain_req, function(err, res) {
                    if (err) {
                      showErrorMessage(err.message);
                    } else {
                      window.location.href = next_page;
                    }
                  });
                } else {
                  showErrorMessage('LINEのログインに失敗しました');
                  location.reload(true);
                }
              });
            } else {
                showErrorMessage('LINEのログインに失敗しました');
                location.reload(true);
            }
          }
        });
    //  }
    //});
  });

  // エラーメッセージを閉じるハンドラ
  // DOMを更新すると自動ログイン処理が起動されるのでマスクしておく
  //$(document).on('click', '.js-close', function () {
  //  var boxMess = $(this).parent('.message');
  //  if (boxMess.hasClass('is-active')) {
  //  boxMess.removeClass('is-active');
  //  }
  //});

  function fblogin() {
    //console.log("FB.login");
    FB.login(function(response) {
      if (response.authResponse) {
        authenticationService.externalIdAuth('graph.facebook.com', response.authResponse.accessToken, new_regist_campain_id, new_regist_campain_req, function(err, res) {
          if (err) {
            showErrorMessage(err.message);
          } else {
            window.location.href = next_page;
          }
        });
      } else {
        showErrorMessage('Facebookのログインに失敗しました');
      }
    }, {scope: 'public_profile,email'});
  }
});

function showErrorMessage(message) {
  var btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
  $('.js-message-login').html(btnCloseErrMessage + message).addClass('is-active');
}

