/*
 * Acquire tokuisakicd list 
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getTokuisakicd(apkihoncd, shopcd, tokuisakicd, isIncludeTaikai) {
  return apiService.get('customer/tokuisakicd', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      isIncludeTaikai: isIncludeTaikai
    }
  });
}

/*
 * Acquire Delivery desired time zone
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} dlvryareacd
 * @params {string} dlvrykankatsukyotencd
 * @params {string} dlvry_day

 */
export function getDeliveryDesiredTime(apkihoncd, shopcd, dlvryareacd, dlvrykankatsukyotencd, dlvry_day) {
  return apiService.get('customer/dlvery/time', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      dlvryareacd: dlvryareacd,
      dlvrykankatsukyotencd: dlvrykankatsukyotencd,
      dlvry_day: dlvry_day
    }
  });
}
