export const PAGES = {
  TOP: 'top.html',
  LINK: 'link.html',
  LOGIN: 'login.html',
  TERMS_OF_SERVICE: 'terms-of-service.html',
  SIGNUP: 'sign-up.html',
  CONFIRMATION_SIGN_UP: 'confirmation-register-use.html',
  CONFIRMATION_PRE_SIGN_UP: 'confirmation-pre-register-use.html',
  CONFIRMATION_PRE_REISSUE: 'confirmation-pre-reissue.html',
  CONFIRMATION_PRE_REGET_ACC: 'confirmation-pre-reget-acc.html',
  CONFIRMATION_SIGN_UP_FROM_OLD: 'confirmation-register-use-from-old.html',
  CONFIRMATION_PRE_SIGN_UP_FROM_OLD: 'confirmation-pre-register-use-from-old.html',
  RE_REGIST_PASSWORD_SIGN_UP_FROM_OLD: 're-regist-password-from-old.html',
  REGISTER_USER: 'new-registration-infomation.html',
  CONFIRMATION_REGISTER_USER: 'confirmation-new-registration-infomation.html',
  SERVICE_INFO: 'mypage-service-information.html',
  CONFIRMATION_SERVICE_INFO: 'mypage-service-information-confirm.html',
  BIKO: 'mypage-biko.html',
  CONFIRMATION_BIKO: 'mypage-biko-confirm.html',
  COMPLETE_BIKO: 'mypage-biko-complete.html',
  COMPLETE_CHANGE_EMAIL: 'mypage-service-information-complete.html',
  COMPLETE_CHANGE_EMAIL2: 'mypage-service-information-complete2.html',
  CHANGE_EMAIL: 'mypage-change-mail.html',
  CONFIRMATION_CHANGE_EMAIL: 'mypage-confirm-change-mail.html',
  CONFIRMATION_CHANGE_EMAIL2: 'mypage-confirm-change-mail2.html',
  CHANGE_EMAIL2: 'mypage-change-mail2.html',
  CART: 'cart-ex1.html',
  CART_EMPTY: 'cart-empty.html',
  NOTIFICATIONS: 'notifications.html',
  NEWS: 'news-list.html',
  MEMBERINFOMATION: 'mypage-member-infomation.html',
  MEMBERINFOMATIONEDIT: 'mypage-member-infomation-edit.html',
  CONFIRMATION_MEMBERINFOMATIONEDIT: 'mypage-member-infomation-edit-comfirm.html',
  COMPLETE_MEMBERINFOMATIONEDIT: 'mypage-member-infomation-edit-complete.html',
  CHANGEADDRESS: 'mypage-change-address.html',
  CONFIRMATION_CHANGEADDRESS: 'mypage-change-address-comfirm.html',
  COMPLETE_CHANGEADDRESS: 'mypage-change-address-complete.html',
  EPOSCARD_REGIST: 'aquaclara_card_regist.html',
  ORDER_LIST: 'mypage-order-list.html',
  ORDER_DETAIL: 'mypage-order-detail.html',
  ORDER_CANNEL: 'mypage-order-cannel.html',
  BOXORDER_DETAIL: 'mypage-boxorder-detail.html',
  BOXORDER_CANNEL: 'mypage-boxorder-cannel.html',
  BOXORDER_PROCEDURE: 'box-order-procedure.html',
  BOXORDER_DELIVERY_NOTE: 'mypage-boxorder-delivery-note.html',
  COMPETED_BOXORDER_MAIL: 'competed-boxorder-mail.html',
  BOX_INFORMATION: 'mypage-box-information.html',
  BOXIMAGE_DISPLAY: 'box-image-display.html',
  REISSUE_PASSWORD_COMPLETE: 'reissue-password-complete.html',
  REISSUE_PASSWORD_COMPLETE2: 'reissue-password-complete2.html',
  NEW_PASSWORD: 'new-password.html',
  NEW_PASSWORD_COMFIRM: 'new-password-comfirm.html',
  NEW_MYAQUAID: 'new-myaquaid.html',
  NEW_MYAQUAID_COMFIRM: 'new-myaquaid-comfirm.html',
  NEW_MYAQUAID_COMFIRM2: 'new-myaquaid-comfirm2.html',
  INQUIRY: 'inquiry.html',
  CONFIRM_INQUIRY: 'inquiry-confirm.html',
  COMPLETE_INQUIRY: 'inquiry-complete.html',
  COMPLETE_MENTAINANCE: 'mypage-procedure-of-water-server-mentainance-complete.html',
  PAYMENT: 'mypage-payment.html',
  MYPAGETOP: 'mypage-top.html',
  COMPETEDORDER: 'competed-order.html',
  COMPETED_BTL_ORDER: 'competed-order-bottle-rally.html',
  CONFIRMATION_ORDER_EX1: 'confirmation-order-ex1.html',
  NEW_REGISTRATION_COMPLETE: 'new-registeration-complete.html',
  CAMPAIGN_APPLI_COMPLETE: 'campaign-appli-complete.html',
  ORDER_CHANGE: 'mypage-order-change.html',
  LINE_LOGIN: 'line-login.html',
  LINE_LOGIN2: 'line-login2.html',
  INTRO_CAMPAIN: 'intro-campain.html',
  INTRO_CAMPAIN2: 'intro-campain2.html',
  INTRO_CAMPAIN3: 'intro-campain3.html',
  COMPETED_NEW_REGISTRATION: 'competed-new_registration.html',
  PLAN: 'mypage-plan.html',
  CONFIRMATION_NINENWARI: 'mypage-plan-ninenwari-confirm.html',
  CONFIRMATION_NINENWARI2: 'mypage-plan-ninenwari-confirm2.html',
  COMPLETE_NINENWARI: 'mypage-plan-ninenwari-complete.html',
  CONFIRMATION_KOSODATE: 'mypage-plan-kosodate-confirm.html',
  KOSODATE: 'mypage-plan-kosodate.html',
  KOSODATE2: 'mypage-plan-kosodate2.html',
  SODASTREAM: 'sodastream_campaign.html',
  GOSHUIN: 'goshuin_campaign.html',
  KIRISHIMA: 'kirishima_campaign.html',
  CHAPLIN_TOP: 'chaplin_top.html',
  AQUACLARA_ENTER: 'aquaclara-enter.html',
  AQUACLARA_ENTER_COMPLETE: 'aquaclara-enter-complete.html',
  AQUACLARA_ENTER_TEMPORARY_COMPLETE: 'aquaclara-enter-temporary-complete.html',
  AQUACLARA_PLAN_CHANGE: 'aquaclara-plan-change.html',
  AQUACLARA_PLAN_CHANGE2: 'aquaclara-plan-change2.html',
  AQUACLARA_CLUBMEMBERTERMS_CHANGE: 'aquaclara-clubmemberterms-change.html',
  AQUACLARA_IMPORTANTSUBJECTS_CHANGE: 'aquaclara-importantsubjects-change.html',
  TEMPORARY_LOGIN_COMPLETE: 'temporary-login-complete.html',
  CHAPLIN_CURRENT: 'chaplin_current.html',
  PROMPT_MAIL_ADDRESS_CHANGE: 'prompt-mail-address-change.html',
  AQUACLARA_MASTERL_INFO: 'aquaclara-master-info.html',
  SELECT_OYAKO: 'select-oyako.html',
  SELECT_OYAKO2: 'select-oyako2.html'
};

export const MAIL = {
  FROM: 'webmaster@aquaclara.co.jp'
};

export const arrCustomerType = new Array('一人暮らし', '夫婦のみ', '親（兄弟）と同居', '親と子供と同居', '夫婦（配偶者）と子供と同居', '事務所（従業員10人以下）', '事務所（従業員11人以上）', 'その他');
export const arrJob = new Array('公務員', 'コンサルタント', 'コンピュータ関連技術職', 'コンピュータ関連以外の技術職', '金融関係', '医師', '弁護士', '総務・人事・事務', '営業・販売', '研究・開発', '広報・宣伝', '企画・マーケティング ', 'デザイン関係', '会社経営・役員', '出版・マスコミ関係', '学生・フリータ', '主婦', 'その他');
export const arrReminder = new Array('母親の旧姓は？', 'お気に入りのマンガは？', '大好きなペットの名前は？', '初恋の人の名前は？', '面白かった映画は？', '尊敬していた先生の名前は？', '好きな食べ物は？');
export const arrMailmagaFlg = new Array('HTML', 'TEXT', '希望しない');
export const arrTerminal = new Array('メール1', 'メール1 + メール2');
export const arrSex = new Array('男性', '女性');
export const otoiawaseType = new Array('アクアクララのサービスについて', '商品について（商品の不明点など）', '注文について（注文確定後の配達日時変更、商品が届かないなど）', '退会について', 'その他ご要望など');
export const otoiawaseKaitoType = new Array('メール', '電話');
export const arrBillMail = new Array('希望しない', '希望する');
export const TAX_CHANGE = {
  DAY: '20191001'
};
export const PRICE_REVISE = {
  BEFORE_REIVSE_TEXT: '2025年4月1日以降の配達の場合:',
  KOSODATE_PLAN_TEXT: '子育てアクアプラン適用中',
  NINENWARI_PLAN_TEXT: '2年割プラン適用中',
  KOSODATE_PLAN_PRE_TEXT: '子育てアクアプラン仮申込み中',
  NINENWARI_PLAN_PRE_TEXT: '2年割プラン仮申込み中',
  WATER_BOTTLE_12L_NAME: 'アクアクララ ウォーターボトル レギュラー（１２Ｌ）',
  WATER_BOTTLE_7L_NAME: 'アクアクララ ウォーターボトル スリム（７Ｌ）',
  WATER_BOTTLE_8L_NAME: 'アクアクララ ウォーターボトル スリム（８Ｌ）',
  KOSODATE_PLAN_TEXT2: '　　　　子育てアクアプラン',
  NINENWARI_PLAN_TEXT2: '　　　　2年割プラン',
  KOSODATE_PLAN_PRE_TEXT2: '　　　　子育てアクアプラン仮申込み中',
  NINENWARI_PLAN_PRE_TEXT2: '　　　　2年割プラン仮申込み中',
};
export const NO_ACCESS_ACCIS = 0;
export const SODASTREAM_CAMPAIGN_ID = 82; // 本番のキャンペーンID
export const OTOMODACHI2023_CAMPAIGN_ID = 84; // 本番のキャンペーンID
export const SODASTREAM_SHNCD = "002321";

// 本番環境は異なるので注意!
//export const MOVING_CAMPAIGN_ID = 637;        // ステージング環境
export const MOVING_CAMPAIGN_ID = 98;       // 本番環境

// 本番環境は異なるので注意!
//export const INTRO_CAMPAIGN_ID = 638;        // ステージング環境
export const INTRO_CAMPAIGN_ID = 100;      // 本番環境

export const GOSHUIN_CAMPAIGN_ID = 53;     // ステージング・本番で同じ値
export const KIRISHIMA_CAMPAIGN_ID = 54;   // ステージング・本番で同じ値
export const KIMETSU_CAMPAIGN_ID = 62;   // ステージング・本番で同じ値

// 本番環境は異なるので注意!
//export const PRODUCT_ID_12L = 190;        // ステージング環境
//export const PRODUCT_ID_7L = 366;         // ステージング環境
//export const PRODUCT_ID_12L_PLAN = 573;   // ステージング環境
//export const PRODUCT_ID_7L_PLAN = 574;    // ステージング環境
//export const PRODUCT_ID_12L_PLAN_NEW = 609; // ステージング環境
//export const PRODUCT_ID_7L_PLAN_NEW = 610;  // ステージング環境

export const PRODUCT_ID_12L = 190;        // 本番環境
export const PRODUCT_ID_7L = 370;         // 本番環境
export const PRODUCT_ID_12L_PLAN = 624;   // 本番環境
export const PRODUCT_ID_7L_PLAN = 625;    // 本番環境
export const PRODUCT_ID_12L_PLAN_NEW = 759; // 本番環境
export const PRODUCT_ID_7L_PLAN_NEW = 760;  // 本番環境
