/*
 * Request intro param
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} accis_campain_cd
 * @params {Number} agencycode
 */

export function getIntroParam(apkihoncd, shopcd, tokuisakicd, accis_campain_cd, agencycode) {
  return apiService.get('customer/intro/param', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      accis_campain_cd: accis_campain_cd,
      agencycode: agencycode
    }
  });
}

/*
 * Request intro
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} accis_campain_cd
 * @params {Number} campain_id
 * @params {Number} intro_kind
 */

export function putIntro(apkihoncd, shopcd, tokuisakicd, accis_campain_cd, campain_id, intro_kind) {
  return apiService.put('customer/intro', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    accis_campain_cd: accis_campain_cd,
    campain_id: campain_id,
    intro_kind: intro_kind
  });
}

/*
 * Request intro origin
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */

export function getIntroOrigin(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/intro/origin', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
    }
  });
}
