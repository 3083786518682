/*
 * Request campaign list
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {Number} getflg
 * @params {Number} page_no
 * @params {Number} page_max_count

 */

export function getCampaignList(apkihoncd, shopcd, getflg, page_no, page_max_count) {
  return apiService.get('customer/campain/info_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      getflg: getflg,
      page_no: page_no,
      page_max_count: page_max_count
    }
  });
}

/*
 * Request campaign detail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {Number} campain_kind
 * @params {Number} campain_id

 */

export function getCampaignDetail(apkihoncd, shopcd, campain_kind, campain_id) {
  return apiService.get('customer/campain/info', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      campain_kind: campain_kind,
      campain_id: campain_id
    }
  });
}

/*
 * Request campaign detail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} campain_id

 */

export function getAppliConfirm(apkihoncd, shopcd, tokuisakicd, campain_id) {
  return apiService.get('customer/campain/present', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      campain_id: campain_id
    }
  });
}

/*
 * Request campaign newregist
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} campain_id
 */

export function getNewRegist(apkihoncd, shopcd, tokuisakicd, campain_id) {
  return apiService.get('customer/campain/newregist', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      campain_id: campain_id
    }
  });
}

/*
 * Request campaign newregist
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} campain_id
 * @params {Number} is_new_regist
 */

export function putNewRegist(apkihoncd, shopcd, tokuisakicd, campain_id, is_new_regist) {
  return apiService.put('customer/campain/newregist', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    campain_id: campain_id,
    is_new_regist: is_new_regist
  });
}

/*
 * Request get myaqua regist campagin
 * @params {Number} campain_id
 * @params {Number} campain_kind
 * @params {Number} list_index
 */

export function getMyAquaRegistCampaign(campain_id, campain_kind, list_index) {
  return new Promise(function (resolve, reject) {
    //console.log("call getMyAquaRegistCampaign");
    //console.log("campain_id=" + campain_id);
    //console.log("campain_kind=" + campain_kind);
    if (campain_kind == 4 || campain_kind == 5) {
      campaignService.getCampaignDetail("001", "000", campain_kind, campain_id).then(function (response) {
        if (response.status == 200 || response.status == 201 || response.status == 204) {
          //console.log("get success campaign detail");
          let dataCampaignDetail = response.data.data;
          let today = new Date();
          today = today.getFullYear() + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) +' '+ ('0' + today.getHours()).slice(-2) +':'+ ('0' + today.getMinutes()).slice(-2) +':'+ ('0' + today.getSeconds()).slice(-2);
          today = new Date(today).getTime();
          //console.log("today=" + today);
          let startDate = dataCampaignDetail.start_date;
          let endDate = dataCampaignDetail.end_date;
          if (startDate != null && endDate != null) {
            startDate = startDate.slice(0, 4) + '/' + startDate.slice(5, 7) + '/' + startDate.slice(8, 10) +' '+ startDate.slice(11, 13) +':'+ startDate.slice(14, 16) +':'+ startDate.slice(17, 19);
            endDate = endDate.slice(0, 4) + '/' + endDate.slice(5, 7) + '/' + endDate.slice(8, 10) +' '+ endDate.slice(11, 13) +':'+ endDate.slice(14, 16) +':'+ endDate.slice(17, 19);
            //console.log("startDate=" + startDate);
            //console.log("endDate=" + endDate);
            startDate = new Date(startDate).getTime();
            endDate = new Date(endDate).getTime();
          }
          if (startDate <= today && today <= endDate) {
            if (typeof dataCampaignDetail.prize != "undefined" || typeof dataCampaignDetail.coupon != "undefined") {
              //console.log("is_myaqua_new_regist=" + dataCampaignDetail.prize['is_myaqua_new_regist']);
              let is_new_regist = 0;
              let external_site_url = '';
              let banner_image = '';
              let banner_image_sp = '';
              if (campain_kind == 4) {
                is_new_regist = dataCampaignDetail.prize['is_myaqua_new_regist'];
              } else {
                is_new_regist = dataCampaignDetail.coupon['is_myaqua_new_regist'];
                external_site_url = dataCampaignDetail.coupon['external_site_url'];
                banner_image = dataCampaignDetail.coupon['banner_image'];
                banner_image_sp = dataCampaignDetail.coupon['banner_image_sp'];
              }
              if (is_new_regist == 1 || is_new_regist == 2) {
                resolve([is_new_regist, list_index, campain_id, external_site_url, banner_image, banner_image_sp]);
              } else {
                resolve([-1, list_index, 0]);
              }
            } else {
              //console.log("dataCampaignDetail.prize is undefined");
              resolve([-1, list_index, 0]);
            }
          } else {
            //console.log("today is not range");
            resolve([-1, list_index, 0]);
          }
        } else {
          //console.log("get not success campaign detail");
          resolve([-1, list_index, 0]);
        }
      }).catch(function (error) {
        //console.log(error);
        resolve([-1, list_index, 0]);
      });
    } else {
      //console.log("campaign kind is not 4");
      resolve([-1, list_index, 0]);
    }
  });
}

