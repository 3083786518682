import * as userService from '../service/user-service';
import * as storageService from '../service/storage-service';
import * as campaignService from '../service/campaign-service';
import * as introService from '../service/intro-service';
import * as aquaclaraenterService from '../service/aquaclaraenter-service';
import * as oyako from '../common/oyako';
import {PAGES} from '../common/constant';
import '../lib/oauth.min';

var AmazonCognitoIdentity = require('amazon-cognito-auth-js');
var CognitoAuth = AmazonCognitoIdentity.CognitoAuth;

export function signUp(userId, password, email, handler) {
  let attributes = [new CognitoUserAttribute({Name: 'email', Value: email})];
  return window.userPool.signUp(userId, password, attributes, null, handler);
}

export function getAttribute(userName, userPool, handler) {
  let cognitoUser = new CognitoUser({Username: userName, Pool: userPool});
  return cognitoUser.getUserAttributes(handler);
}

export function reSignUp(userName, userPool, handler) {
  let cognitoUser = new CognitoUser({Username: userName, Pool: userPool});
  return cognitoUser.resendConfirmationCode(handler);
}

export function confirmRegistration(userName, userPool, verifyCode, handler) {
  let cognitoUser = new CognitoUser({Username: userName, Pool: userPool});
  return cognitoUser.confirmRegistration(verifyCode, true, handler);
}

/**
 @params {}
 */
export function login(username, password, next_page, new_regist_campain_id, new_regist_campain_req) {

  // Pass login to Cognito if skip login check box checked.
  //console.log('login next_page:'+next_page);
  //console.log('login new_regist_campain_id:'+new_regist_campain_id);

  // Login with Cognito
  let authenticationData = {Username: username, Password: password};
  let authenticationDetails = new AuthenticationDetails(authenticationData);
  let userData = {Username: username, Pool: userPool};

  storageService.clearToken();
  storageService.clearAuthToken();
  storageService.clearExtAuthDate();

  var cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (authresult) {
      cognitoUserCur = userPool.getCurrentUser();
      if (cognitoUserCur != null) {
        cognitoUserCur.getSession(function (err, sessresult) {
          if (sessresult) {
            var token_id= authresult.getAccessToken().getJwtToken();
            var cognitoProvider = new AWS.CognitoIdentityServiceProvider();
            cognitoProvider.getUser({AccessToken:token_id}, function(err, data) {
              if (err) {
                //console.log('err:'+JSON.stringify(data));
                let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                $('.js-message-login').html(btnCloseErrMessage + 'マイアクアIDかパスワードが正しくありません。<br>メールアドレスをご入力の場合マイアクアにご登録されているものと異なる可能性があります。<br>ログイン情報が不明な場合はご利用いただいている担当店舗へご確認下さい。').addClass('is-active');
              } else {
                var nickname = cognitoUserCur.username;
                //console.log('nickname1:'+nickname);
                data.UserAttributes.forEach(function(item) {
                  if (item["Name"] == "preferred_username") {
                    nickname = item["Value"];
                  }
                });
                //console.log('nickname2:'+nickname);
                userService.getUserLoginMyaqua(nickname).then(function (response) {
                  if (response.status == 200 || response.status == 201 || response.status == 204) {
                    var data = response.data.data;
                    //console.log('nickname3:'+nickname);
                    storageService.setUser({
                      'apkihoncd': data.apkihoncd, 'shopcd': data.shopcd, 'tokuisakicd': data.tokuisakicd, 'customer_id': data.customer_id, 'password': password, 'myaquaid': nickname, 'cognitousername': cognitoUserCur.username
                    });
                    storageService.clearCart();
                    storageService.clearBoxCart();
                    storageService.clearPlanState();
                    storageService.clearPlanSetCode12L();
                    storageService.clearPlanSetCode7L();
                    storageService.clearPlanSetCode12LNew();
                    storageService.clearPlanSetCode7LNew();
                    storageService.clearIsEnterPlan();
                    storageService.clearIsShowPriceChange();
                    oyako.clearOyako();
                    var remember_me = $('#agree').prop("checked");
                    if (remember_me === true) {
                      var acclist = { userID: username, pswd: password}
                      localStorage.setItem("auto_login", JSON.stringify(acclist));
                    }
                    var aqualcaraInfo = new Promise(function (resolve, reject) {
                      aquaclaraenterService.chkAquaclaraInfo(data.apkihoncd, data.shopcd, data.tokuisakicd, data.customer_id).then(function (response) {
                        if (response.status == 200 || response.status == 201 || response.status == 204) {
                            resolve(response.data.data.result);
                        } else {
                            resolve(1);
                        }
                      }).catch(function (error) {
                        resolve(1);
                      });
                    });
                    var notreachedInfo = new Promise(function (resolve, reject) {
                      userService.getUserNotreachedMail(data.apkihoncd, data.shopcd, data.tokuisakicd, data.customer_id).then(function (response) {
                        if (response.status == 200 || response.status == 201 || response.status == 204) {
                            resolve(response.data.data);
                        } else {
                            resolve(-1);
                        }
                      }).catch(function (error) {
                        resolve(-1);
                      });
                    });
                    var aquaclaraMasterInfo = new Promise(function (resolve, reject) {
                      userService.getAauaclaraMasterInfo("001", "000").then(function (response) {
                        if (response.status == 200 || response.status == 201 || response.status == 204) {
                            resolve(response.data.data);
                        } else {
                            resolve(-1);
                        }
                      }).catch(function (error) {
                        resolve(-1);
                      });
                    });
                    Promise.all([aqualcaraInfo, notreachedInfo, aquaclaraMasterInfo]).then(function (result) {
                      var [ainfo, ninfo, aminfo] = [result[0], result[1], result[2]];
                      if (ainfo == 0) {
                        location.href = PAGES.AQUACLARA_ENTER;
                      } else if (ainfo == 1) {
                        if (ninfo == -1 || (next_page && next_page.indexOf('mypage-accept-confirm-change-mail2.html') != -1) || (storageService.getJumpUrl() &&  storageService.getJumpUrl().indexOf('mypage-accept-confirm-change-mail2.html') != -1)
                                        || (next_page && next_page.indexOf('mypage-change-mail2.html') != -1) || (storageService.getJumpUrl() &&  storageService.getJumpUrl().indexOf('mypage-change-mail2.html') != -1)) {
                          //console.log("login1:storageService.getMyAquaCampaignReq()=" + storageService.getMyAquaCampaignReq());
                          //console.log("login2:new_regist_campain_id=" + new_regist_campain_id);
                          if (storageService.getMyAquaCampaignReq() == 1 || storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_id > 0) {
                            if (new_regist_campain_id == 0) {
                              new_regist_campain_id = storageService.getMyAquaCampaignId();
                              //console.log("login3:new_regist_campain_id=" + new_regist_campain_id);
                            }
                            if (storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_req == 2) {
                              introService.getIntroOrigin(data.apkihoncd, data.shopcd, data.tokuisakicd).then(function (response) {
                                if (response.status == 200 || response.status == 201 || response.status == 204) {
                                  let intro = response.data.data;
                                  if (intro.result == '1') {
                                    campaignService.putNewRegist(data.apkihoncd, data.shopcd, data.tokuisakicd, parseInt(new_regist_campain_id), 2).then(function (response) {
                                      //console.log("login4");
                                      if (response.status == 200 || response.status == 201 || response.status == 204) {
                                        //console.log("login5");
                                        storageService.setMyAquaCampaignReq(0);
                                      }
                                      nextPage(aminfo, next_page);
                                    }).catch(function (error) {
                                      //console.log("login6");
                                      nextPage(aminfo, next_page);
                                    });
                                  } else {
                                    nextPage(aminfo, next_page);
                                  }
                                } else {
                                  nextPage(aminfo, next_page);
                                }
                              }).catch(function (error) {
                                nextPage(aminfo, next_page);
                              });
                            } else {
                              campaignService.putNewRegist(data.apkihoncd, data.shopcd, data.tokuisakicd, parseInt(new_regist_campain_id), 2).then(function (response) {
                                //console.log("login4");
                                if (response.status == 200 || response.status == 201 || response.status == 204) {
                                  //console.log("login5");
                                  storageService.setMyAquaCampaignReq(0);
                                }
                                nextPage(aminfo, next_page);
                              }).catch(function (error) {
                                //console.log("login6");
                                nextPage(aminfo, next_page);
                              });
                            }
                          } else {
                            //console.log("login7");
                            let campain_url = storageService.getCampainDetailUrl();
                            if (typeof campain_url == "string") {
                              storageService.clearCampainDetailUrl();
                              if (campain_url.indexOf('campaign_detail.html') !== -1) {
                                nextPage(aminfo, campain_url);
                              } else {
                                let jump_url = storageService.getJumpUrl();
                                if (typeof jump_url == "string") {
                                  storageService.clearJumpUrl();
                                  if (jump_url.indexOf('.html') !== -1) {
                                    nextPage(aminfo, jump_url);
                                  } else {
                                    nextPage(aminfo, next_page);
                                  }
                                } else {
                                  nextPage(aminfo, next_page);
                                }
                              }
                            } else {
                              let jump_url = storageService.getJumpUrl();
                              if (typeof jump_url == "string") {
                                storageService.clearJumpUrl();
                                if (jump_url.indexOf('.html') !== -1) {
                                  nextPage(aminfo, jump_url);
                                } else {
                                  nextPage(aminfo, next_page);
                                }
                              } else {
                                nextPage(aminfo, next_page);
                              }
                            }
                          }
                        } else {
                          // メールアドレス再登録依頼のケース => next_pageへ遷移する処理はしない => アクアクララからのお知らせがある場合は先に遷移する
                          if (aminfo != -1) {
                            storageService.setAquaclaramasterinfoTitle(aminfo['infotitle']);
                            storageService.setAquaclaramasterinfoBody(aminfo['infobody']);
                            storageService.setAquaclaramasterinfoImageUrl(aminfo['infoimageurl']);
                          }
                          if (ninfo != -1) {
                            storageService.setNotreachedMailAddress(ninfo['email']);
                            storageService.setNotreachedMailDate(ninfo['notreacheddate']);
                          }
                          if (aminfo != -1) {
                            location.href = PAGES.AQUACLARA_MASTERL_INFO;
                          } else {
                            location.href = PAGES.PROMPT_MAIL_ADDRESS_CHANGE;
                          }
                        }
                      } else if (ainfo == 2) {
                        location.href = PAGES.AQUACLARA_PLAN_CHANGE;
                      } else if (ainfo == 3) {
                        location.href = PAGES.AQUACLARA_CLUBMEMBERTERMS_CHANGE;
                      } else if (ainfo == 4) {
                        location.href = PAGES.AQUACLARA_IMPORTANTSUBJECTS_CHANGE;
                      } else if (ainfo == 5) {
                        location.href = PAGES.AQUACLARA_PLAN_CHANGE2;
                      }
                    });

                  } else {
                    let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                    if (response.status == 400) {
                      $('.js-message-login').html(btnCloseErrMessage + 'マイアクアIDかパスワードが正しくありません。<br>メールアドレスをご入力の場合マイアクアにご登録されているものと異なる可能性があります。<br>ログイン情報が不明な場合はご利用いただいている担当店舗へご確認下さい。').addClass('is-active');
                    } else if (response.status == 401) {
                      $('.js-message-login').html(btnCloseErrMessage + 'マイアクアIDかパスワードが正しくありません。<br>メールアドレスをご入力の場合マイアクアにご登録されているものと異なる可能性があります。<br>ログイン情報が不明な場合はご利用いただいている担当店舗へご確認下さい。').addClass('is-active');
                    } else {
                      $('.js-message-login').html(btnCloseErrMessage + 'ただいまアクセスが集中しております。<br>しばらくたってから再度お試しください。').addClass('is-active');
                    }
                  }
                }).catch(function (error) {
                  let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                  $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
                });
              }
            });
          }
        });
      }
    },
    onFailure: function (err) {
      var isNotConfirmed = 0;
      if (err && (err.code == 'UserNotConfirmedException')) {
        isNotConfirmed = 1;
      }

      var loginInfo = new Promise(function (resolve, reject) {
        userService.getUserLoginMyaqua(username, password).then(function (response) {
          if (response.status == 200 || response.status == 201 || response.status == 204) {
            resolve(response.data.data);
          } else {
            resolve(-1);
          }
        }).catch(function (error) {
          resolve(-1);
        });
      });
      Promise.all([loginInfo]).then(function (result) {
        var data = result[0];
        if (data != -1) {
          storageService.setUser({
            'apkihoncd': data.apkihoncd, 'shopcd': data.shopcd, 'tokuisakicd': data.tokuisakicd, 'customer_id': data.customer_id, 'password': password, 'myaquaid': username
          });
          storageService.clearCart();
          storageService.clearBoxCart();
          storageService.clearPlanState();
          storageService.clearPlanSetCode12L();
          storageService.clearPlanSetCode7L();
          storageService.clearPlanSetCode12LNew();
          storageService.clearPlanSetCode7LNew();
          storageService.clearIsEnterPlan();
          storageService.clearIsShowPriceChange();
          oyako.clearOyako();
          var remember_me = $('#agree').prop("checked");
          if (remember_me === true) {
            var acclist = { userID: username, pswd: password}
            localStorage.setItem("auto_login", JSON.stringify(acclist));
          }
          storageService.setTemporaryRegist(1);
          location.href = PAGES.TEMPORARY_LOGIN_COMPLETE;
        } else {
          userService.getUserLoginOld(username, password).then(function (response) {
            if (response.status == 200 || response.status == 201 || response.status == 204) {
              let data = response.data.data;
              let email = data.email;

              localStorage.setItem("register_mail", email)

              try {
                if (isNotConfirmed) {
                  storageService.setUser({'myaquaid': username, 'email': email, 'password': password});
                  location.href = PAGES.CONFIRMATION_SIGN_UP_FROM_OLD;
                } else {
                  //Call webapi aws
                  if (password.length >= 6) {
                    //signUp(username, password, email, function (err, result) {
                    var up = new CognitoUserPool({UserPoolId:env.UserPoolId,ClientId:env.ClientIdForSkipConfirmation});
                    var attributes = [new CognitoUserAttribute({Name: 'email', Value: email})];
                    up.signUp(username, password, attributes, null, function (err, result) {
                      if (err && (err.code == 'InvalidParameterException' || err.code == 'UsernameExistsException')) {
                        let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                        $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
                        return;
                      }
                      storageService.setUser({'myaquaid': username, 'email': email, 'password': password});
                      setTimeout(function(username, password){
                       login(username, password);
                      }, 100, username, password);
                    });
                  } else {
                    storageService.setUser({'myaquaid': username, 'email': email, 'password': password});
                    location.href = PAGES.RE_REGIST_PASSWORD_SIGN_UP_FROM_OLD;
                  }
                }
              } catch (err) {
                let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
              }
            } else {
              let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
              $('.js-message-login').html(btnCloseErrMessage + 'マイアクアIDかパスワードが正しくありません。<br>メールアドレスをご入力の場合マイアクアにご登録されているものと異なる可能性があります。<br>ログイン情報が不明な場合はご利用いただいている担当店舗へご確認下さい。').addClass('is-active');
            }
          }).catch(function (error) {
            let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
            $('.js-message-login').html(btnCloseErrMessage + 'マイアクアIDかパスワードが正しくありません。<br>メールアドレスをご入力の場合マイアクアにご登録されているものと異なる可能性があります。<br>ログイン情報が不明な場合はご利用いただいている担当店舗へご確認下さい。').addClass('is-active');
          });
        }
      });
    }
  });

}

function nextPage(aminfo, next_page) {
  if (aminfo != -1 && next_page == PAGES.TOP) {
    // アクアクララからのお知らせがあり かつ TOPページ遷移(next_page指定なし) の場合 => アクアクララからのお知らせに遷移
    storageService.setAquaclaramasterinfoTitle(aminfo['infotitle']);
    storageService.setAquaclaramasterinfoBody(aminfo['infobody']);
    storageService.setAquaclaramasterinfoImageUrl(aminfo['infoimageurl']);
    location.href = PAGES.AQUACLARA_MASTERL_INFO;
  } else {
    // アクアクララからのお知らせがない もしくは next_page指定ありの場合 => next_pageに遷移
    location.href = next_page;
  }
}

export function checkCareerMail(email) {
  //console.log('email='+email);
  let index = email.indexOf("@");
  if (index == -1) {
    return false;
  }
  //console.log('email index='+index);
  let domain = email.slice(index + 1);
  //console.log('domain='+domain);
  if (domain == "docomo.ne.jp" ||
      domain == "ezweb.ne.jp" ||
      domain == "au.com" ||
      domain == "i.softbank.jp" ||
      domain == "softbank.ne.jp" ||
      domain == "ymobile.ne.jp") {
    //console.log('email check:true');
    return true;
  }
  //console.log('email check:false');
  domain = email.slice(index + 3);
  if (domain == "vodafone.ne.jp") {
    return true;
  }
  return false;
}

export function externalIdAuth(provider, accessToken, new_regist_campain_id, new_regist_campain_req, callback) {
  var logins = {};
  logins[provider] = accessToken;
  AWS.config.region = env.Region;
  var cognitoidentity = new AWS.CognitoIdentity();
  cognitoidentity.getId({IdentityPoolId:env.IdentityPoolId,Logins:logins}, function(err, res) {
    if (err) {
      callback(new Error("マイアクアへのログインに失敗しました"), null);
    } else {
      var identityId = res.IdentityId;
      storageService.setAuthToken(accessToken);
      let nowDay = new Date();
      let nowTime = nowDay.getTime();
      let nowSecond = Math.floor(nowTime / 1000);
      storageService.setExtAuthDate(nowSecond);
      userService.getUserCognitoIdentityLogins(identityId).then(function (response) {
        if (response && response.data && response.data.data && (response.data.data.indexOf(provider) >= 0)) {
          userService.getUserLoginMyaqua(identityId).then(function (response) {
            if (response.status == 200) {
              let data = response.data.data;
              storageService.setUser({
                'apkihoncd': data.apkihoncd, 'shopcd': data.shopcd, 'tokuisakicd': data.tokuisakicd, 'customer_id': data.customer_id, 'password': null, 'myaquaid': identityId
              });
              storageService.clearCart();
              storageService.clearBoxCart();
              storageService.clearPlanState();
              storageService.clearPlanSetCode12L();
              storageService.clearPlanSetCode7L();
              storageService.clearPlanSetCode12LNew();
              storageService.clearPlanSetCode7LNew();
              storageService.clearIsEnterPlan();
              storageService.clearIsShowPriceChange();
              oyako.clearOyako();
              //console.log("login ext1:storageService.getMyAquaCampaignReq()=" + storageService.getMyAquaCampaignReq());
              //console.log("login ext2:new_regist_campain_id=" + new_regist_campain_id);
              if (storageService.getMyAquaCampaignReq() == 1 || storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_id > 0) {
                if (new_regist_campain_id == 0) {
                  new_regist_campain_id = storageService.getMyAquaCampaignId();
                  //console.log("login ext3:new_regist_campain_id=" + new_regist_campain_id);
                }
                if (storageService.getMyAquaCampaignReq() == 2 || new_regist_campain_req == 2) {
                  introService.getIntroOrigin(data.apkihoncd, data.shopcd, data.tokuisakicd).then(function (response) {
                    if (response.status == 200 || response.status == 201 || response.status == 204) {
                      let intro = response.data.data;
                      if (intro.result == '1') {
                        campaignService.putNewRegist(data.apkihoncd, data.shopcd, data.tokuisakicd, parseInt(new_regist_campain_id), 2).then(function (response) {
                          //console.log("login4");
                          if (response.status == 200 || response.status == 201 || response.status == 204) {
                            //console.log("login5");
                            storageService.setMyAquaCampaignReq(0);
                          }
                          callback(null, 'SUCCESS');
                        }).catch(function (error) {
                          callback(null, 'SUCCESS');
                        });
                      } else {
                        callback(null, 'SUCCESS');
                      }
                    } else {
                      callback(null, 'SUCCESS');
                    }
                  }).catch(function (error) {
                    callback(null, 'SUCCESS');
                  });
                } else {
                  campaignService.putNewRegist(data.apkihoncd, data.shopcd, data.tokuisakicd, parseInt(new_regist_campain_id), 2).then(function (response) {
                    //console.log("login ext4");
                    if (response.status == 200 || response.status == 201 || response.status == 204) {
                      //console.log("login ext5");
                      storageService.setMyAquaCampaignReq(0);
                    }
                    callback(null, 'SUCCESS');
                  }).catch(function (error) {
                    //console.log("login ext6");
                    callback(null, 'SUCCESS');
                  });
                }
              } else {
                //console.log("login ext7");
                callback(null, 'SUCCESS');
              }
            } else {
              storageService.clearAuthToken();
              storageService.clearExtAuthDate();
              callback(new Error("マイアクアへのログインに失敗しました"), null);
            }
          }).catch(function (error) {
            storageService.clearAuthToken();
            storageService.clearExtAuthDate();
            callback(new Error("マイアクアへのログインに失敗しました"), null);
          });
        } else {
          storageService.clearAuthToken();
          storageService.clearExtAuthDate();
          callback(new Error("外部ID連携が確認できません"), null);
        }
      }).catch(function(error){
        storageService.clearAuthToken();
        storageService.clearExtAuthDate();
      });
    }
  });
}

export function facebookLogin(url) {
  storageService.clearToken();
  storageService.clearAuthToken();
  storageService.clearExtAuthDate();
  cognitoAuth('Facebook', url);
}

export function googleLogin(url) {
  storageService.clearToken();
  storageService.clearAuthToken();
  storageService.clearExtAuthDate();
  cognitoAuth('Google', url);
}

export function twitterLogin() {
    storageService.clearToken();
    storageService.clearAuthToken();
    storageService.clearExtAuthDate();
    OAuth.initialize(env.OAuthIo.PublicKey);
    OAuth.popup("twitter",
        function(err, res) {
          if (res) {
            var oauthToken = res.oauth_token+";"+res.oauth_token_secret;
            AWS.config.region = env.Region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                {
                    IdentityPoolId: env.IdentityPoolId,
                    Logins: {
                        'api.twitter.com': oauthToken
                    }
                });
            AWS.config.credentials.get(function(){
                var identityId = AWS.config.credentials.identityId;
                var accessKeyId = AWS.config.credentials.accessKeyId;
                var secretAccessKey = AWS.config.credentials.secretAccessKey;
                var sessionToken = AWS.config.credentials.sessionToken;
                //console.log('oauthToken '+ oauthToken);
                storageService.setAuthToken(oauthToken);
                let nowDay = new Date();
                let nowTime = nowDay.getTime();
                let nowSecond = Math.floor(nowTime / 1000);
                storageService.setExtAuthDate(nowSecond);
                //console.log('Cognito Identity ID '+ identityId);
                userService.getUserLoginMyaqua(identityId).then(function (response) {
                  if (response.status == 200) {
                    //console.log('check myaqua login ok???');
                    let data = response.data.data;
                    storageService.setUser({
                      'apkihoncd': data.apkihoncd, 'shopcd': data.shopcd, 'tokuisakicd': data.tokuisakicd, 'customer_id': data.customer_id, 'password': null, 'myaquaid': identityId
                    });
                    storageService.clearCart();
                    storageService.clearBoxCart();
                    storageService.clearPlanState();
                    storageService.clearPlanSetCode12L();
                    storageService.clearPlanSetCode7L();
                    storageService.clearPlanSetCode12LNew();
                    storageService.clearPlanSetCode7LNew();
                    storageService.clearIsEnterPlan();
                    storageService.clearIsShowPriceChange();
                    oyako.clearOyako();
                    location.href = next_page;
                  } else {
                    //console.log('login check error1');
                    let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                    $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
                    storageService.clearAuthToken();
                    storageService.clearExtAuthDate();
                  }
                }).catch(function (error) {
                  //console.log(error);
                  //console.log('login check error2');
                  let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
                  $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
                  storageService.clearAuthToken();
                  storageService.clearExtAuthDate();
                });
                //console.log('Cognito Key '+ accessKeyId);
                //console.log('Cognito Secret Key '+ secretAccessKey);
                //console.log('Cognito SessionToken '+ sessionToken);
            });
          } else {
            //console.log('twitter auth error');
          }
        }
    );

}

export function twitterSignUp(callback) {
    OAuth.initialize(env.OAuthIo.PublicKey);
    OAuth.popup("twitter",
        function(err, res) {
          if (res) {
            var oauthToken = res.oauth_token+";"+res.oauth_token_secret;
            AWS.config.region = env.Region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                {
                    IdentityPoolId: env.IdentityPoolId,
                    Logins: {
                        'api.twitter.com': oauthToken
                    }
                });
            AWS.config.credentials.get(function(){
                var identityId = AWS.config.credentials.identityId;
                var accessKeyId = AWS.config.credentials.accessKeyId;
                var secretAccessKey = AWS.config.credentials.secretAccessKey;
                var sessionToken = AWS.config.credentials.sessionToken;
                //console.log('oauthToken '+ oauthToken);
                storageService.setAuthToken(oauthToken);
                let nowDay = new Date();
                let nowTime = nowDay.getTime();
                let nowSecond = Math.floor(nowTime / 1000);
                storageService.setExtAuthDate(nowSecond);
                //console.log('Cognito Identity ID '+ identityId);
                callback(identityId);
            });
          } else {
            //console.log('twitter auth error');
          }
        }
    );
}

export function extIdLogout() {
  var redirect_url = window.location.protocol + '//' + window.location.host + '/login.html';
  var token = storageService.getToken();
  if (token) {
    var authData = {
      ClientId : env.ClientId, // Your client id here
      AppWebDomain : env.AppWebDomain,
      TokenScopesArray : ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin', 'phone'],
      RedirectUriSignIn : redirect_url,
      RedirectUriSignOut : redirect_url
    };
    var auth = new CognitoAuth(authData);
    auth.signOut();
    storageService.clearToken();
  }
  token = storageService.getAuthToken();
  if (token) {
    storageService.clearAuthToken();
  }
  storageService.clearExtAuthDate();
}

//export function facebookLogin() {
  // This is called with the results from from FB.getLoginStatus().
//  function statusChangeCallback(response) {
    //console.log('statusChangeCallback');
    //console.log(response);
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
//    if (response.status === 'connected') {
//      testAPI();
//      cognitoAuth('Facebook');
      /*
      // Add the Facebook access token to the Cognito credentials login map.
      AWS.config.region = 'ap-northeast-1'; // リージョン
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'ap-northeast-1:71ea1796-2bd3-4493-8590-a9bea8f606f9',
          Logins: {
            'graph.facebook.com': response.authResponse.accessToken
          }
      });
       // Obtain AWS credentials
       AWS.config.credentials.get(function(){
          var credential = AWS.config.credentials;
          alert(JSON.stringify(credential));
       });
       */
//    } else {
      // The person is not logged into your app or we are unable to tell.
      // document.getElementById('status').innerHTML = 'Please log ' +
      //  'into this app.';
      //console.log("FB login");
//      FB.login(function(response) {
        //console.log("response");
//        if (response.authResponse) {
          //user just authorized your app
//          testAPI();
//          cognitoAuth('Facebook');
//        }
//	  });
//    }
//  }
//
  // This function is called when someone finishes with the Login
  // Button.  See the onlogin handler attached to it in the sample
  // code below.
//  function checkLoginState() {
//    FB.getLoginStatus(function(response) {
//      statusChangeCallback(response);
//    });
//  }
//
//  window.fbAsyncInit = function() {
//    FB.init({
//      appId      : '1759391644182044',
//      cookie     : true,  // enable cookies to allow the server to access 
//                          // the session
//      xfbml      : true,  // parse social plugins on this page
//      version    : 'v2.8' // use graph api version 2.8
//    });
//
    // Now that we've initialized the JavaScript SDK, we call 
    // FB.getLoginStatus().  This function gets the state of the
    // person visiting this page and can return one of three states to
    // the callback you provide.  They can be:
    //
    // 1. Logged into your app ('connected')
    // 2. Logged into Facebook, but not your app ('not_authorized')
    // 3. Not logged into Facebook and can't tell if they are logged into
    //    your app or not.
    //
    // These three cases are handled in the callback function.
//
//    FB.getLoginStatus(function(response) {
//      statusChangeCallback(response);
//    });
//  };
//
  // Load the SDK asynchronously
//  (function(d, s, id) {
//    var js, fjs = d.getElementsByTagName(s)[0];
//    if (d.getElementById(id)) return;
//    js = d.createElement(s); js.id = id;
//    js.src = "https://connect.facebook.net/en_US/sdk.js";
//    fjs.parentNode.insertBefore(js, fjs);
//  }(document, 'script', 'facebook-jssdk'));

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
//  function testAPI() {
    //console.log('Welcome!  Fetching your information.... ');
//    FB.api('/me', function(response) {
      //console.log('Successful login for: ' + response.name);
//    });
//  }
//}

function cognitoAuth(target, url) {
  // Logged into your app and Facebook.
  var redirect_url = window.location.protocol + '//' + window.location.host;
  if (url == 1) {
    redirect_url = redirect_url + '/login.html';
  } else {
    redirect_url = redirect_url + '/sign-up.html';
  }
  //console.log(redirect_url);
  var authData = {
    ClientId : env.ClientId, // Your client id here
    AppWebDomain : env.AppWebDomain,
    TokenScopesArray : ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin', 'phone'],
    RedirectUriSignIn : redirect_url,
    RedirectUriSignOut : redirect_url,
    dentityProvider: target
  };
  //console.log('auth:1');
  var auth = new CognitoAuth(authData);
  //console.log('auth:2');
  auth.userhandler = {
    onSuccess: function(result) {
      //console.log(result);
    },
    onFailure: function(err) {
      //console.log(err);
    }
  };
  //console.log('auth:3');
  auth.useCodeGrantFlow();
  //console.log('auth:4');
  auth.getSession();
  //console.log('auth:5');
}

export function myaquaAuth(url) {
  getCognitoAuthToken(url, function (username, token) {
    if (token) {
      //console.log("myaquaAuth token:" + token);
      storageService.setToken(token);
      let nowDay = new Date();
      let nowTime = nowDay.getTime();
      let nowSecond = Math.floor(nowTime / 1000);
      storageService.setExtAuthDate(nowSecond);
    }
    if (username) {
      //console.log('check myaqua login:' + username);
      //call webapi user login myqua
      userService.getUserLoginMyaqua(username).then(function (response) {
        if (response.status == 200) {
          //console.log('check myaqua login ok???');
          let data = response.data.data;
          storageService.setUser({
            'apkihoncd': data.apkihoncd, 'shopcd': data.shopcd, 'tokuisakicd': data.tokuisakicd, 'customer_id': data.customer_id, 'password': null, 'myaquaid': username
          });
          storageService.clearCart();
          storageService.clearBoxCart();
          storageService.clearPlanState();
          storageService.clearPlanSetCode12L();
          storageService.clearPlanSetCode7L();
          storageService.clearPlanSetCode12LNew();
          storageService.clearPlanSetCode7LNew();
          storageService.clearIsEnterPlan();
          storageService.clearIsShowPriceChange();
          oyako.clearOyako();
          location.href = next_page;
        } else {
          //console.log('login check error1');
          let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
          $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
          storageService.clearToken();
        }
      }).catch(function (error) {
        //console.log(error);
        //console.log('login check error2');
        let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
        $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
        storageService.clearToken();
      });
    } else {
      let btnCloseErrMessage = '<button class="message-close js-close">Close</button>';
      $('.js-message-login').html(btnCloseErrMessage + 'ログインに失敗しました。').addClass('is-active');
    }
  });
}

export function myaquaSignupAuth(url, callback) {
  //console.log("myaquaSignupAuth");
  getCognitoAuthToken(url, function (username, token) {
    if (token) {
      //console.log("myaquaSignupAuth token:" + token);
      storageService.setToken(token);
      let nowDay = new Date();
      let nowTime = nowDay.getTime();
      let nowSecond = Math.floor(nowTime / 1000);
      storageService.setExtAuthDate(nowSecond);
    }
    callback(username);
  });
}

export function myaquaSignupFail() {
  storageService.clearToken();
}

function getCognitoAuthToken(url, callback) {
  //console.log("getCognitoAuthToken");
  var redirect_url = window.location.protocol + '//' + window.location.host;
  if (url == 1) {
    redirect_url = redirect_url + '/login.html';
  } else {
    redirect_url = redirect_url + '/sign-up.html';
  }
  //console.log(redirect_url);
  var authData = {
    ClientId : env.ClientId, // Your client id here
    AppWebDomain : env.AppWebDomain,
    TokenScopesArray : ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin', 'phone'],
    RedirectUriSignIn : redirect_url,
    RedirectUriSignOut : redirect_url
  };
  var auth = new CognitoAuth(authData);
  auth.userhandler = {
    onSuccess: function(result) {
      //console.log("success");
      callback(auth.getCurrentUser(), result.getAccessToken().getJwtToken());
    },
    onFailure: function(err) {
      //console.log("fail");
      callback(null, null);
    }
  };
  auth.useCodeGrantFlow();
  auth.parseCognitoWebResponse(window.location.href);
}

export function checkLogging() {
  if (cognitoUserCur != null) {
    cognitoUserCur.getSession(function (err, sessresult) {
      if (sessresult) {
        location.href = next_page;
      }
    });
  } else {
    $('body').removeClass('box-hidden');
  }
}

export function getTokenId(callback) {
  //console.log("getTokenId");
  var token = storageService.getToken();
  if (token) {
    //console.log("getTokenId token:" + token);
    callback(token);
  } else {
    if (cognitoUserCur != null) {
      cognitoUserCur.getSession(function (err, sessresult) {
        var idToken = sessresult.getAccessToken().getJwtToken();
        //console.log("getTokenId token:" + idToken);
        callback(idToken);
      });
    } else {
        callback(null);
    }
  }
}

export function getAuthTokenId(callback) {
  //console.log("getAuthTokenId");
  var token = storageService.getAuthToken();
  if (token) {
    //console.log("getAuthTokenId token:" + token);
    callback(token);
  } else {
    callback(null);
  }
}
