/*
 * Acquire plan state
 * @params {String} apkihoncd
 * @params {String} shopcd
 * @params {String} tokuisakicd
 */
export function getPlanState(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/revised_price/plan_state', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Acquire plan set code
 */
export function getPlanSetCode() {
  return apiService.get('customer/revised_price/plan_setcd', {
    params: {
      apkihoncd: null
    }
  });
}

/*
 * Acquire is changed
 */
export function getIsChanged() {
  return apiService.get('customer/revised_price/is_changed', {
    params: {
      apkihoncd: null
    }
  });
}

/*
 * Acquire is changed by ymd
 */
export function getIsChangedByYmd(ymd) {
  return apiService.get('customer/revised_price/is_changed_by_ymd', {
    params: {
      ymd: ymd
    }
  });
}

/*
 * Get plan
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 */
export function getPlan(apkihoncd, shopcd, tokuisakicd) {
  return apiService.get('customer/revised_price/plan', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd
    }
  });
}

/*
 * Update plan
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Object} update_data
 */
export function putPlan(apkihoncd, shopcd, tokuisakicd, update_data) {
  return apiService.put('customer/revised_price/plan', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    update_data: update_data
  });
}

/*
 * Acquire is enter plan
 */
export function getIsEnterPlan() {
  return apiService.get('customer/revised_price/is_enter_plan', {
    params: {
      apkihoncd: null
    }
  });
}

/*
 * Acquire is show price change
 */
export function getIsShowPriceChange() {
  return apiService.get('customer/revised_price/is_show_pricechange', {
    params: {
      apkihoncd: null
    }
  });
}
