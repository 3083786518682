import {PAGES} from '../common/constant';

(function(definition){
    if (typeof exports === "object") {
        module.exports = definition();
    } else if (typeof define === "function" && define.amd) {
        define(definition);
    } else {
        LineLogin = definition();
    }
})(function(){
    'use strict';

    var LineLogin = function LineLogin(){};

    LineLogin.prototype = {
        popup : function(authorize_url, client_id, redirect_url, callback) {
            localStorage.removeItem('link_login_response');
            var state = '';
            var i = 32;
            var orgStr = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            for (; i > 0; --i) {
                state += orgStr[Math.round(Math.random() * (orgStr.length - 1))];
            }
            var queryparameters = "response_type=code&scope=openid&client_id="+client_id+"&redirect_uri="+redirect_url+"&state="+state;
            var url = (authorize_url == null) ? "https://access.line.me/oauth2/v2.1/authorize?"+queryparameters : authorize_url+"?identity_provider=LINE&"+queryparameters;
            var defer, frm, getMessage, gotmessage, interval, res, url, wnd, wndTimeout, wnd_options, wnd_settings, opts;

            gotmessage = false;
            getMessage = function(e) {
        //console.log("getMessage:1");
                if (!gotmessage) {
        //console.log("getMessage:2");
                    try {
                        wnd.close();
                    } catch (_error) {}
                    //opts.data = e.data;
                    //oauthio.request.sendCallback(opts, defer);
        //console.log("getMessage:3");
                    opts.callback(null, JSON.parse(e.data));
        //console.log("getMessage:4");
                    return gotmessage = true;
                }
            };
            wnd = void 0;
            frm = void 0;
            wndTimeout = void 0;
            defer = $.Deferred();
            opts = {};
            if (!opts.state) {
                //opts.state = sha1.create_hash();
                //opts.state_type = "client";
            }

            wnd_settings = {
                width: Math.floor(window.outerWidth * 0.8),
                height: Math.floor(window.outerHeight * 0.5)
            };
            if (wnd_settings.width < 1000) {
                wnd_settings.width = 1000;
            }
            if (wnd_settings.height < 630) {
                wnd_settings.height = 630;
            }
            wnd_settings.left = Math.floor(window.screenX + (window.outerWidth - wnd_settings.width) / 2);
            wnd_settings.top = Math.floor(window.screenY + (window.outerHeight - wnd_settings.height) / 8);
            wnd_options = "width=" + wnd_settings.width + ",height=" + wnd_settings.height;
            wnd_options += ",toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0";
            wnd_options += ",left=" + wnd_settings.left + ",top=" + wnd_settings.top;

            opts.callback = function(e, r) {
        //console.log("potscallback:1");
                if (window.removeEventListener) {
        //console.log("potscallback:2");
                    window.removeEventListener("message", getMessage, false);
                } else if (window.detachEvent) {
        //console.log("potscallback:3");
                    window.detachEvent("onmessage", getMessage);
                } else {
        //console.log("potscallback:4");
                    if (document.detachEvent) {
                        document.detachEvent("onmessage", getMessage);
                    }
                }
                opts.callback = function() {};
                if (wndTimeout) {
        //console.log("potscallback:5");
                    clearTimeout(wndTimeout);
                    wndTimeout = undefined;
                }
                if (callback) {
        //console.log("potscallback:6");
                    return callback(e, r);
                } else {
        //console.log("potscallback:7");
                    return undefined;
                }
            };
            if (window.attachEvent) {
        //console.log("attachEvent:1");
                window.attachEvent("onmessage", getMessage);
            } else if (document.attachEvent) {
        //console.log("attachEvent:2");
                document.attachEvent("onmessage", getMessage);
            } else {
                if (window.addEventListener) {
        //console.log("attachEvent:3");
                    window.addEventListener("message", getMessage, false);
                }
            }
            if (typeof chrome !== "undefined" && chrome.runtime && chrome.runtime.onMessageExternal) {
        //console.log("attachEvent:4");
                chrome.runtime.onMessageExternal.addListener(function(request, sender, sendResponse) {
        //console.log("attachEvent:5");
                    request.origin = sender.url.match(/^.{2,5}:\/\/[^\/]+/)[0];
                    return getMessage(request);
                });
            }
            //if (!frm && (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > 0)) {
            //  frm = document.createElement("iframe");
            //  frm.src = config.oauthd_url + "/auth/iframe?d=" + encodeURIComponent(Url.getAbsUrl("/"));
            //  frm.width = 0;
            //  frm.height = 0;
            //  frm.frameBorder = 0;
            //  frm.style.visibility = "hidden";
            //  document.body.appendChild(frm);
            //}
            localStorage.setItem('line_login_step', 0);
            localStorage.setItem('line_login_url', url);
            var interval_cnt = 0;
            wnd = window.open(PAGES.LINE_LOGIN, "Authorization", wnd_options);
        //console.log("window:1");
            //window.location.href = "https://staging-myaqua-line-federation.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=LINE&response_type=code&scope=openid&client_id=7fg8e88jj3ab4gadn5k752qgi7&redirect_uri=https://stg.aquaclara-cloud.com/api/v1/line/idpresponse&state=8tl8sqeb4fPtqZnp2HPBRZzDfS4CaX0k";
        //console.log("window:2");
            //wnd = window;
            if (wnd) {
        //console.log("window:3");
                wnd.focus();
                interval = window.setInterval(function() {
        //console.log("window:4 " + localStorage.getItem('line_login_step'));
                    interval_cnt++;
        //console.log("window:4-0-0 wnd:" + (wnd ? wnd.closed : wnd));
                    if (interval_cnt > 30 && wnd.closed) {
        //console.log("window:4-0 timeout wnd:" + (wnd ? wnd.closed : wnd));
                        // Edge�̓���̃o�[�W�����Ŏq�E�B���h�E�����localStorage�̕ύX��擾�ł���15�b���o�߂����ꍇ��������G���[��Ԃ�
                        window.clearInterval(interval);
                        return opts.callback(new Error("timeout"));
                    }
                    if (localStorage.getItem('line_login_step') == 2) {
                        interval_cnt = 0;
        //console.log("window:4-1");
                        var data = localStorage.getItem('link_login_response');
                        if (wnd === null || (wnd.closed && (window.navigator.userAgent.indexOf('Android') == -1 || window.navigator.userAgent.indexOf('Chrome') == -1)) || data) {
        //console.log("window:5");
                            window.clearInterval(interval);
                            if (data) {
                                wnd.close();
        //console.log("window:6");
                                localStorage.removeItem('link_login_response');
                                opts.callback(null, JSON.parse(data));
        //console.log("window:7");
                                return gotmessage = true;
                            }
        //console.log("window:8");
                            if (!gotmessage) {
        //console.log("window:9");
                                if (defer != null) {
                                    defer.reject(new Error("The popup was closed"));
                                }
        //console.log("window:10");
                                if (opts.callback && typeof opts.callback === "function") {
                                    return opts.callback(new Error("The popup was closed"));
                                }
                            }
                        }
                    }
                }, 500);
            } else {
                if (defer != null) {
                    defer.reject(new Error("Could not open a popup"));
                }
                if (opts.callback && typeof opts.callback === "function") {
                    opts.callback(new Error("Could not open a popup"));
                }
            }
        //console.log("window:11");
            return defer != null ? defer.promise() : void 0;
        }
    }

    return LineLogin;
});
