/*
 * Acquire product list
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} lcategorycd
 * @params {string} mcategorycd
 * @params {Number} category_level
 * @params {Number} page_no,
 * @params {Number} page_max_count,
 * @params {string} shncd,
 */
export function getProductList(apkihoncd, shopcd, tokuisakicd, lcategorycd, mcategorycd, scategorycd, category_level, page_no, page_max_count, shncd) {
  return apiService.get('customer/product_category/product', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      lcategorycd: lcategorycd,
      mcategorycd: mcategorycd,
      scategorycd: scategorycd,
      category_level: category_level,
      page_no: page_no,
      page_max_count: page_max_count,
      shncd: shncd,
    }
  });
}

/*
 * Acquire product list with status
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {string} lcategorycd
 * @params {string} mcategorycd
 * @params {Number} category_level
 * @params {Number} page_no,
 * @params {Number} page_max_count,
 * @params {string} shncd,
 * @params {Number[]} status,
 */
export function getProductListWithStatus(apkihoncd, shopcd, tokuisakicd, lcategorycd, mcategorycd, scategorycd, category_level, page_no, page_max_count, shncd, status) {
  return apiService.get('customer/product_category/product', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      lcategorycd: lcategorycd,
      mcategorycd: mcategorycd,
      scategorycd: scategorycd,
      category_level: category_level,
      page_no: page_no,
      page_max_count: page_max_count,
      shncd: shncd,
      status: status,
    }
  });
}

/*
 * Acquire water bottle list
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} page_no,
 * @params {Number} page_max_count,
 */
export function getWaterBottleProductList(apkihoncd, shopcd, tokuisakicd, page_no, page_max_count) {
  return apiService.get('customer/product/waterbottle/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      page_no: page_no,
      page_max_count: page_max_count
    }
  });
}

/*
 * Acquire product detail
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {string} tokuisakicd
 * @params {Number} product_id
 * @params {string} mode
 */
export function getProductDetail(apkihoncd, shopcd, tokuisakicd, product_id, mode) {
  return apiService.get('customer/product_detail/list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      tokuisakicd: tokuisakicd,
      product_id: product_id,
      mode: mode
    }
  });
}

/*
 * Acquire recommended items list
 * @params {string} apkihoncd
 * @params {string} shopcd
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getBestProductList(apkihoncd, shopcd, page_no, page_max_count) {
  return apiService.get('customer/best_product_list', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      page_no: page_no,
      page_max_count: page_max_count
    }
  });
}

/*
 * Acquire product search
 * @params {String} apkihoncd
 * @params {String} shopcd
 * @params {String} tokuisakicd
 * @params {String} keyword
 * @params {number} page_no
 * @params {number} page_max_count
 */
export function getProductSearchList(apkihoncd, shopcd, tokuisakicd, keyword, page_no, page_max_count) {
  return apiService.post('customer/product/search', {
    apkihoncd: apkihoncd,
    shopcd: shopcd,
    tokuisakicd: tokuisakicd,
    keyword: keyword,
    page_no: page_no,
    page_max_count: page_max_count
  });
}

/*
 * Acquire tax rate
 * @params {String} apkihoncd
 * @params {String} shopcd
 * @params {String} shncd
 */
export function getTaxRate(apkihoncd, shopcd, shncd) {
  return apiService.get('customer/product/tax_rate', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      shncd: shncd
    }
  });
}

/*
 * Acquire is new tax
 */
export function getIsNewTax() {
  return apiService.get('customer/product/is_newtax', {
    params: {
      apkihoncd: null
    }
  });
}

/*
 * Acquire tax bunrui
 * @params {String} apkihoncd
 * @params {String} shopcd
 * @params {String} shncd
 */
export function getTaxBunrui(apkihoncd, shopcd, ymd) {
  return apiService.get('customer/product/tax_bunrui', {
    params: {
      apkihoncd: apkihoncd,
      shopcd: shopcd,
      ymd: ymd
    }
  });
}
